import React, { Component } from "react";
import PropTypes from "prop-types";

export class PhysicianCards extends Component {
  constructor(props) {
    super(props);
    this.chatContainer = null;
    this.requesting = false;
    this.state = { requesting: false };

    this.handleScroll = this.handleScroll.bind(this);
    this.assignChatContainerRef = this.assignChatContainerRef.bind(this);
  }

  componentDidUpdate() {
    const scrollSection = document.getElementsByClassName("results-list-wrapper")
    if (!this.props.loading && this.props.doctors.length === 0) {
      scrollSection[0].style.height = "auto"
      scrollSection[0].style.paddingTop = "21px"
    }
  }

  render() {
    return (
      <div>
        <div
          id="scroll-section"
          ref={this.assignChatContainerRef}
          className="results-list-wrapper"
        >
          {this.props.doctors.map(doctor => (
            <div
              key={doctor.Id + Math.random() * 100}
              className="results-item-wrapper"
              attributo={doctor.Id}
              onClick={() => {
                this.props.showPhysicianDetails(doctor);
              }}
            >
              <div className="number-wrapper">
                <div className="result-index">
                  <div className="index">{doctor.markerNumber}</div>
                </div>
              </div>
              <div className="doctor-arrow">
                <div className="doctor-details">
                  <h4 className="name">
                    {doctor.First} {doctor.Last},{" "}
                    {doctor.ProfessionalDesignation}
                  </h4>
                  <div className="address-wrapper">
                    <p>
                      {doctor.Address1}
                      {doctor.Address2}
                      <br />
                      {doctor.City}, {doctor.State} {doctor.Zip}
                    </p>
                  </div>
                  <p className="phone-number">{doctor.Phone}</p>
                  <p className="distance">
                    {Math.round(doctor.DistanceFromRequest)} miles away
                  </p>
                </div>
                <div className="physician-arrow">
                  <i className="fa fa-chevron-right" icon="chevron-right" />
                </div>
              </div>
              {doctor.PediatricSpecialist === true && (
                <p className="pediatric-specialist">
                  <i className="fa fa-check-circle" />{" "}
                  <span>Pediatric patients welcome</span>
                </p>
              )}
            </div>
          ))}
          <div className="loader-wrapper">
            {this.props.loading ? <div className="load" /> : null}
          </div>
          {
            !this.props.loading && this.props.doctors.length === 0 ?
            <div className="no-results-found-wrapper">
              <div className="no-results-text">
                <span>No results found.</span><br />
                <p>
                  To see more results, try changing location or panning and zooming the map.
                </p>
              </div>
            </div> : null
          }
        </div>
      </div>
    );
  }

  handleScroll() {
    const node = document.getElementById("scroll-section");
    const scrollHeight = node.scrollHeight - node.offsetHeight - 10;

    function noScroll() {
      node.scrollTo(0, scrollHeight)
    }
      if (this.props.firstSearch && node.scrollTop >= scrollHeight && this.props.doctors.length < 30) {
        this.chatContainer.addEventListener("scroll", noScroll())
        this.props.getPhysicians()
          .then( () => {
            if (window.innerWidth < 768) {
              node.scrollTo({
                top: scrollHeight + (node.offsetHeight / 1.45),
                left: 0,
                behavior: 'smooth'
              })
            } else {
              node.scrollTo({
                top: scrollHeight + (node.offsetHeight / 1.37),
                left: 0,
                behavior: 'smooth'
              })
            }
            this.chatContainer.removeEventListener("scroll", noScroll())
          })
      // }
    }

  }
  assignChatContainerRef(target) {
    this.chatContainer = target;
    if (this.chatContainer) {
      this.chatContainer.addEventListener("scroll", this.handleScroll);
    }
  }
}

PhysicianCards.propTypes = {
  doctor: PropTypes.array,
  getPhysicians: PropTypes.func,
  loading: PropTypes.bool,
  showPhysicianDetails: PropTypes.func,
  emailPhysicianDetails: PropTypes.func,
  redoBounds: PropTypes.object,
  firstSearch: PropTypes.bool
};

export default PhysicianCards;
