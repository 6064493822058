import React from 'react';
import { Link } from 'gatsby';
import heroSleepSpecialists from '@images/hero/hero-sleep-specialists.jpg';
import BrightcoveVideo from '@component-structure/BrightcoveVideo';
import { getPath } from '@component-utility/pagelist';
import { TrackingWrapper } from '@component-utility/TrackingTools';

const SleepSpecialists = () => (
	<div>
		<div className='hero'>
			<div className='hero--header'>
				<div className='container hero__container'>
					<div className='row'>
						<div className='col-12 col-md-7 col__indent--left'>
							<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
								Why You Should
								<br className='d-block d-sm-none' /> See{' '}
								<br className='d-none d-sm-block' />a Sleep
								<br className='d-block d-sm-none' /> Specialist
							</h1>
							<p className='hero__text'>
								Narcolepsy is a complicated condition and is often
								misunderstood—even by many doctors. Primary care
								physicians generally have limited training in sleep
								disorders, so their knowledge about narcolepsy is also
								limited. They are generally not very comfortable
								diagnosing narcolepsy and often do not use the appropriate
								tools and tests to make the diagnosis. Therefore, they
								usually refer patients to a sleep specialist to be
								screened for narcolepsy.
							</p>
						</div>
						<div className='col-12 col-md-5 align-self-stretch hero__image-container pr-0'>
							<div className='hero__image d-none d-md-block'>
								<div className='hero__image--shadow'>
									<img
										src={heroSleepSpecialists}
										alt='Why You Should See a Sleep Specialist'
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='hero--footer'>
				<div className='container l__indent-mobile--1'>
					<div className='row justify-content-center'>
						<div className='col-12 col-md-11'>
							In fact, a family doctor may work an entire career without
							seeing a single case of narcolepsy, and the odds are even
							lower that he or she would recognize narcolepsy in a child.
							That’s why you should visit a sleep specialist if you or your
							child has any of the major narcolepsy symptoms. Some
							physicians even specialize in sleep disorders in children.
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className='mt-60 mt-sm-40 object-box-tile'>
			<div className='container object-box-tile__indent'>
				<div className='pb-40 pb-sm-20'>
					<div className='row mt-20 align-items-center'>
						<div className='col-12 col-md-7 pr-50 pr-md-15'>
							<div className='object--shadow'>
								<h2 className='text__title text__size--base text--brand-secondary'>
									In a study of different doctors, here’s how often they
									correctly diagnosed their patients who had narcolepsy:
								</h2>
								<div className='d-flex mt-20 justify-content-start mb-sm-20'>
									<div className='text-right text__weight--bold text__size--base-mobile line-height--27'>
										<p>Neurologists</p>
										<p>Internists</p>
										<p>General practitioners</p>
										<p>Psychiatrists</p>
										<p>Pediatricians</p>
									</div>
									<div className='w-25 pl-10 pr-10'>
										<p
											className='progress object__progress--base bg--brand-white'
											style={{ height: '27px' }}>
											<span
												className='progress-bar bg--brand-secondary-light'
												role='progressbar'
												style={{ width: '100px' }}
												aria-valuenow='100'
												aria-valuemin='0'
												aria-valuemax='100'
											/>
										</p>
										<p
											className='progress object__progress--base bg--brand-white'
											style={{ height: '27px' }}>
											<span
												className='progress-bar bg--brand-secondary-light'
												role='progressbar'
												style={{ width: '40px' }}
												aria-valuenow='40'
												aria-valuemin='0'
												aria-valuemax='100'
											/>
										</p>
										<p
											className='progress object__progress--base bg--brand-white'
											style={{ height: '27px' }}>
											<span
												className='progress-bar bg--brand-secondary-light'
												role='progressbar'
												style={{ width: '37px' }}
												aria-valuenow='37'
												aria-valuemin='0'
												aria-valuemax='100'
											/>
										</p>
										<p
											className='progress object__progress--base bg--brand-white'
											style={{ height: '27px' }}>
											<span
												className='progress-bar bg--brand-secondary-light'
												role='progressbar'
												style={{ width: '20px' }}
												aria-valuenow='20'
												aria-valuemin='0'
												aria-valuemax='100'
											/>
										</p>
										<p
											className='progress object__progress--base bg--brand-white'
											style={{ height: '27px' }}>
											<span
												className='progress-bar bg--brand-secondary-light'
												role='progressbar'
												style={{ width: '1px' }}
												aria-valuenow='1'
												aria-valuemin='0'
												aria-valuemax='100'
											/>
										</p>
									</div>
									<div className='text__weight--bold w-auto text__size--base-mobile line-height--27'>
										<p>55%</p>
										<p>24%</p>
										<p>22%</p>
										<p>11%</p>
										<p>0%</p>
									</div>
								</div>
							</div>
						</div>
						<div className='col-12 col-md-5 pl-40 pl-sm-15 mt-sm-30'>
							<div className='object-box-tile__info'>
								<p className='text__title--extralight'>
									Many doctors are not aware that narcolepsy affects
									children.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className='container object-callout--indent'>
			<div className='row align-items-stretch'>
				<TrackingWrapper gtmTrackId='sleepSpecConvoStarter'>
					<Link
						to={getPath('NarcolepsyConversationStarter')}
						className='col-12 col-md-6 mt-20'>
						<div className='object-callout__element object-callout__element--informedDiscussion bg--brand-secondary h-100 d-flex align-items-center'>
							<div className='object-callout__text'>
								<p className='text__title text__size--xxlarge mb-0'>
									Have an informed discussion about narcolepsy with your
									sleep specialist.
								</p>
								<span className='text__title--light'>
									Get the Narcolepsy Conversation{' '}
									<span className='d-inline-block'>
										Starter{' '}
										<i
											className='fa fa-angle-right ml-5'
											aria-hidden='true'
										/>
									</span>
								</span>
							</div>
						</div>
					</Link>
				</TrackingWrapper>
				<TrackingWrapper gtmTrackId='sleepSpecFASS'>
					<Link
						to={getPath('FindASleepSpecialist')}
						className='col-12 col-md-6 mt-20'>
						<div className='object-callout__element object-callout__element--findSpecialist bg--brand-primary h-100 d-flex align-items-center'>
							<div className='object-callout__text'>
								<p className='text__title mb-0'>
									Where can you find a sleep specialist?
								</p>
								<span className='text__title--light'>
									Find a sleep specialist near{' '}
									<span className='d-inline-block'>
										you{' '}
										<i
											className='fa fa-angle-right ml-5'
											aria-hidden='true'
										/>
									</span>
								</span>
							</div>
						</div>
					</Link>
				</TrackingWrapper>
			</div>
		</div>

		<div className='container mt-75 mt-sm-40'>
			<div className='row justify-content-center'>
				<div className='col-12 col-md-11 col-lg-7 text-left text-md-center object-video object-video--indent'>
					<BrightcoveVideo
						videoId='5851705195001'
						overlayTxt='After Years of Suffering, Kiah Finally Got an Accurate Diagnosis'
						gtmTrackId='sleepSpec-kiah-video'
					/>
				</div>
				<div className='object-video__text col-12 col-md-11 col-lg-7 text-md-center'>
					<span className='object-video__text-title'>
						"I really started to just look into researching sleep disorders,
						trying to find somebody that could help us."
					</span>
					<span className='object-video__text-info'>
						Watch Kiah and his mom discuss finally getting a diagnosis.{' '}
					</span>
				</div>
			</div>
		</div>
	</div>
);

export default SleepSpecialists;
