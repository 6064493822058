import React, { Fragment, useState, useRef } from "react"
import { Link } from "gatsby"
import { getPath } from "@component-utility/pagelist"
import {
  ResizeTrigger,
  pdfGetParams,
  ErrorMessage,
  isEmpty,
  isValidEmail,
  objToArr,
  scrollToRef,
} from "@component-utility/utils"
import { Score } from "@component-utility/ScreenerElements"
import icoDownload from "@images/icon/icon-download.svg"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import {
  customEventTypes,
  gtmDataLayerPush,
  TrackingWrapper,
} from "@component-utility/TrackingTools"
import {
  sendmeResult,
  fetchPhysicians,
  geolocateZipcode,
  getApiBasePath,
} from "@component-utility/requests"
import { FormMaterialInput } from "@component-utility/formUtils"
import SubmitButtonLoading from "@component-utility/SubmitButtonLoading"
import config from "@config/config"
import ReCAPTCHA from "react-google-recaptcha"
import { storage } from "@component-utility/utils"

const gooReClientKey = config.RECAPTCHA_CLIENT

export const ScreenerNavigation = (props) => {
  const executeScrollESS = () => scrollToRef(props.refESS)
  const executeScrollSNS = () => scrollToRef(props.refSNS)

  return (
    <div
      className={`screener-nav ${props.sticky ? "screener-nav-sticky" : ""}`}
    >
      <div className="container">
        <div className="screener-nav-links">
          <p className="nav-title">SYMPTOM SCREENERS:</p>
          <p
            className={`screener-link ${
              !props.unscrolled && !props.sectionSNS ? "active" : ""
            }`}
            onClick={executeScrollESS}
          >
            MEASURING SLEEPINESS
          </p>
          <p className="nav-pipe">|</p>
          <p
            className={`screener-link ${
              !props.unscrolled && props.sectionSNS ? "active" : ""
            }`}
            onClick={executeScrollSNS}
          >
            EXPLORING SYMPTOMS
          </p>
        </div>
      </div>
      <hr className="nav-bottom-border" />
      <div className="screener-gradient" />
    </div>
  )
}

export const ScreenerHeader = (props) => (
  <div className="screener-header-wrapper hero__container">
    <div className="row align-items-center">
      <div className="col-12 col-xl-10">
        <h1 className="text__title text__title--1 text--body-copy-dark mb-4">
          {props.title}
        </h1>
        <h2 className="header-subtitle text__title">
          {props.subtitle}{" "}
          <span className="screener-type">({props.screenerType})</span>
        </h2>
        <p className="screener-header-text hero__text">{props.text}</p>
        {props.disclaimer && <p className="screener-header-text gero__text">{props.disclaimer}</p>}
        <p className="screener-header-instructions hero__text">
          {props.instructions}
        </p>
      </div>
    </div>
  </div>
)

export const ShareScoresRow = (props) => (
  <div className="share-these-scores">
    <p className="text__title share">
      Share these important scores with your healthcare provider
    </p>
    <p className="share-text">{props.shareText}</p>
    <TrackingWrapper gtmTrackId={"scoreResultsFASS_" + props.screenerType}>
      <Link to={getPath("FindASleepSpecialist")} className="btn btn--primary">
        Find a sleep specialist near you{" "}
        <i className="fa fa-angle-right ml-5" />
      </Link>
    </TrackingWrapper>
  </div>
)

export class AdultScoreDetailESS extends Score {
  constructor(props) {
    super(props)
    this.refScoreSectionESS = React.createRef()
    this.downloadClicked = this.downloadClicked.bind(this)
    this.emailClicked = this.emailClicked.bind(this)
  }

  componentDidMount() {
    scrollToRef(this.refScoreSectionESS)
  }

  downloadClicked() {
    this.props.setScreenerType("ESS")
    this.props.setIsOpenDownload(true)
  }

  emailClicked() {
    this.props.setScreenerType("ESS")
    this.props.setIsOpenEmail(true)
  }

  render() {
    const { essScore = 10 } = this.props
    return (
      <Fragment>
        <ResizeTrigger onResize={(breakpoint) => this.updateSize(breakpoint)} />
        <div className="ess-score-detail" ref={this.refScoreSectionESS}>
          <p className="screener-type text__title text--body-copy-dark">
            The Epworth Sleeping Scale <span>(ESS)</span>
          </p>
          <div className="row">
            <div className="col-12 col-md-4">
              <h2 className="text__weight--semibold text__title--3 mb-0">
                Your ESS score is:
              </h2>
              <p className="text__title score-number mb-0">{essScore}</p>
              <p className="score-explanation text__size--small mb-0 mb-sm-30 visible-md-up">
                An ESS score greater than 10 suggests excessive daytime
                sleepiness. An ESS score of 16 or higher suggests a high level
                of excessive daytime sleepiness. You should discuss your ESS
                score with your sleep specialist.
              </p>
            </div>
            <div className="col-12 col-md-8">
              <div className="text-left">
                <p className="text__weight--semibold text__title--3 mb-0">
                  Interpreting ESS Scores
                </p>
                <div className="d-flex flex-md-column flex-row position-relative mt-40 mt-sm-30">
                  <div className="step-screener__score">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        aria-valuenow={this.positionEss(essScore)}
                        style={{
                          width: this.positionEss(essScore),
                        }}
                      />
                      {/* set style width 0% */}
                      <div className="score-value score-value--0">
                        <span>0</span>
                      </div>

                      {/* set style width 43% */}
                      <div className="score-value score-value--10">
                        <span>10</span>
                      </div>

                      {/* set style width 70% */}
                      <div className="score-value score-value--16">
                        <span>16</span>
                      </div>

                      {/* set style width 100% */}
                      <div className="score-value score-value--24">
                        <span>24</span>
                      </div>
                    </div>
                  </div>
                  <div className="step-screener__score-text d-flex flex-column flex-md-row justify-content-stretch">
                    <div className="score-text score-text--1">
                      Normal levels of sleepiness
                    </div>
                    <div className="score-text score-text--2">
                      Suggests excessive daytime sleepiness (EDS)
                    </div>
                    <div className="score-text score-text--3">
                      Suggests a high level of EDS
                    </div>
                  </div>
                </div>
                {/* <div className="download-and-print">
                  <div className="d-p-background">
                    <p className="download-and-print-text">
                      Your scores are available as a PDF that includes the
                      Narcolepsy Conversation Starter
                    </p>
                    <div className="d-p-links">
                      <TrackingWrapper gtmTrackId="AdultDownloadESS">
                        <div className="mr-50" onClick={this.downloadClicked}>
                          DOWNLOAD RESULTS{" "}
                          <img
                            src={icoDownload}
                            alt="icon download"
                            width="24px"
                            height="24px"
                            className="align-baseline ml-5"
                          />
                        </div>
                      </TrackingWrapper>
                      <TrackingWrapper gtmTrackId="AdultEmailESS">
                        <div onClick={this.emailClicked}>
                          EMAIL RESULTS{" "}
                          <i
                            className="ion ion-ios-email-outline email-icon ml-10"
                            aria-hidden="true"
                          />
                        </div>
                      </TrackingWrapper>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export class AdultScoreDetailSNS extends Score {
  constructor(props) {
    super(props)
    this.refScoreSectionSNS = React.createRef()
    this.downloadClicked = this.downloadClicked.bind(this)
    this.emailClicked = this.emailClicked.bind(this)
  }

  componentDidMount() {
    scrollToRef(this.refScoreSectionSNS)
  }

  downloadClicked() {
    this.props.setScreenerType("SNS")
    this.props.setIsOpenDownload(true)
  }

  emailClicked() {
    this.props.setScreenerType("SNS")
    this.props.setIsOpenEmail(true)
  }

  render() {
    const { snsScore = 8 } = this.props
    return (
      <Fragment>
        <ResizeTrigger onResize={(breakpoint) => this.updateSize(breakpoint)} />
        <div className="sns-score-detail" ref={this.refScoreSectionSNS}>
          <p className="screener-type text__title text--body-copy-dark">
            The Swiss Narcolepsy Scale
          </p>
          <div className="row">
            <div className="col-12 col-md-4">
              <h2 className="text__weight--semibold text__title--3 mb-0">
                Your SNS score is:
              </h2>
              <p className="text__title score-number mb-0">{snsScore}</p>
              <p className="score-explanation text__size--small mb-0 mb-sm-30 visible-md-up">
                An SNS calculated score that is less than 0 is suggestive of
                narcolepsy with cataplexy. You should discuss your SNS score with
                your sleep specialist.
              </p>
            </div>
            <div className="col-12 col-md-8">
              <div className="text-left">
                <p className="text__weight--semibold text__title--3 mb-0">
                  Interpreting SNS Scores
                </p>
                <div className="d-flex flex-md-column flex-row position-relative mt-40 mt-sm-30">
                  <div className="step-screener__score step-screener__score-2">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        aria-valuenow={this.positionSns(snsScore)}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{
                          width: this.positionSns(snsScore),
                        }}
                      />
                      {/* set style width 0% */}
                      <div className="score-value score-value-2--50">
                        <span>-50</span>
                      </div>

                      {/* set style width 15% */}
                      <div className="score-value score-value-2--40">
                        <span>-40</span>
                      </div>

                      {/* set style width 30% */}
                      <div className="score-value score-value-2--30">
                        <span>-30</span>
                      </div>

                      {/* set style width 45% */}
                      <div className="score-value score-value-2--20">
                        <span>-20</span>
                      </div>

                      {/* set style width 60% */}
                      <div className="score-value score-value-2--10">
                        <span>-10</span>
                      </div>

                      {/* set style width 75% */}
                      <div className="score-value score-value-2--0">
                        <span>0</span>
                      </div>

                      {/* set style width 85% */}
                      <div className="score-value score-value-2--6">
                        <span>6</span>
                      </div>
                    </div>
                  </div>
                  <div className="step-screener__score-text step-screener__score-text-2 d-flex flex-column flex-md-row justify-content-stretch">
                    <div className="score-text score-text-2--1">
                      Suggests narcolepsy with cataplexy
                    </div>
                    <div className="score-text score-text-2--2">
                      Not suggestive of narcolepsy with cataplexy
                    </div>
                  </div>
                </div>
                {/* <div className="download-and-print">
                  <div className="d-p-background">
                    <p className="download-and-print-text">
                      Your scores are available as a PDF that includes the
                      Narcolepsy Conversation Starter
                    </p>
                    <div className="d-p-links">
                      <TrackingWrapper gtmTrackId="AdultDownloadSNS">
                        <div className="mr-50" onClick={this.downloadClicked}>
                          DOWNLOAD RESULTS{" "}
                          <img
                            src={icoDownload}
                            alt="icon download"
                            width="24px"
                            height="24px"
                            className="align-baseline ml-5"
                          />
                        </div>
                      </TrackingWrapper>
                      <TrackingWrapper gtmTrackId="AdultEmailSNS">
                        <div onClick={this.emailClicked}>
                          EMAIL RESULTS{" "}
                          <i
                            className="ion ion-ios-email-outline email-icon ml-10"
                            aria-hidden="true"
                          />
                        </div>
                      </TrackingWrapper>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export const OptionalInformationOverlay = (props) => {
  const ageRangeDropdown = useRef(null)
  const ageRanges = ["18-24", "25-34", "35-44", "45-54", "55 or older"]

  const onGenderSelect = (e, val) => {
    props.setOptionalGender(val)
  }

  const onAgeSelect = (e) => {
    var val = ageRangeDropdown.current.value
    props.setOptionalAgeRange(val)
  }

  const onForSelect = (e, val) => {
    props.setOptionalTakingFor(val)
  }

  const continueBtnClicked = (e) => {
    props.toggle(e)
    props.submitOptionalInfo()
  }

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={(e) => props.toggle(e)}
      centered={true}
      className={"modal-dialog-ess-optional"}
    >
      <ModalBody>
        <div className="row mt-50 mt-md-30 mb-40 mb-sm-0">
          <div className="modal-title col-12">
            <span className="text--body-copy-dark text__title text__title--3">
              Optional Information
            </span>
          </div>
        </div>
        <form className="form-general">
          <div className="row mt-20">
            <div className="gender col-12 col-md-auto mt-10">
              <p className="text__title text__size--small">Gender</p>
              <div className="custom-control custom-radio custom-control-inline ml-1">
                <input
                  type="radio"
                  id="genderM"
                  name="gender"
                  className="custom-control-input font-weight-normal"
                  onChange={(e) => onGenderSelect(e, "M")}
                />
                <label
                  className="custom-control-label font-weight-normal"
                  htmlFor="genderM"
                >
                  Male
                </label>
              </div>
              <div className="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="genderF"
                  name="gender"
                  className="custom-control-input font-weight-normal"
                  onChange={(e) => onGenderSelect(e, "F")}
                />
                <label
                  className="custom-control-label font-weight-normal"
                  htmlFor="genderF"
                >
                  Female
                </label>
              </div>
            </div>
            <div className="age-range col-12 col-md-auto mt-10">
              <label
                className="text__title text__size--small d-block"
                htmlFor="inlineFormCustomSelect"
              >
                Age Range
              </label>
              <select
                className="custom-select w-auto"
                id="inlineFormCustomSelect"
                onChange={(e) => onAgeSelect(e)}
                ref={ageRangeDropdown}
              >
                <option value="">Select</option>
                {ageRanges.map((a, i) => (
                  <option value={a} key={i}>
                    {a}
                  </option>
                ))}
              </select>
            </div>
            <div className="taking-for col-12 col-md-auto mt-sm-15 mt-10">
              <p className="text__title text__size--small">
                I am taking this screener for:
              </p>
              <div className="text-left">
                <div className="custom-control custom-radio custom-control-inline mt-sm-0">
                  <input
                    type="radio"
                    id="takingScreenMySelf"
                    name="takingScreen"
                    className="custom-control-input"
                    onChange={(e) => onForSelect(e, "myself")}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="takingScreenMySelf"
                  >
                    Myself
                  </label>
                </div>
                <div className="custom-control custom-radio custom-control-inline mt-sm-0 mr-0">
                  <input
                    type="radio"
                    id="takingScreenFamilyMember"
                    name="takingScreen"
                    className="custom-control-input"
                    onChange={(e) => onForSelect(e, "family")}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="takingScreenFamilyMember"
                  >
                    Family member or friend
                  </label>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <div className="row w-75 m-auto">
          <div className="col-12 text-center">
            <Button
              className="btn btn--primary btn--step mr-0"
              onClick={(e) => continueBtnClicked(e)}
            >
              <span>CONTINUE</span>
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export const NoAnswersOverlay = (props) => {
  const backBtnClicked = (e) => {
    props.toggle(e)
  }

  //console.log("props", props);

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={(e) => props.toggle(e)}
      centered={true}
      className={"modal-dialog-no-answers"}
    >
      <ModalHeader>
        <button type="button" className="close" aria-label="Close" onClick={(e) => props.toggle(e)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </ModalHeader>
      <ModalBody>
        <div className="row mt-50 mt-md-30 mb-30">
          <div className="modal-title col-12">
            <p className="text--body-copy-dark text__title text__title--3 fill-all-questions">
              Please fill out all the questions on the screener to see your
              result.
            </p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="row w-75 m-auto">
          <div className="col-12 text-center">
            <Button
              className="btn btn--primary btn--step mr-0"
              onClick={(e) => backBtnClicked(e)}
            >
              BACK
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  )
}

const getPDFType = (adult) => {
  if (adult) {
    let ess
    let sns
    if (
      typeof window !== "undefined" &&
      typeof window.localStorage === "object"
    ) {
      ess = storage.getObject("ess")
      sns = storage.getObject("sns")
    }
    if (ess && sns) {
      return "both"
    } else if (ess) {
      return "ess"
    } else if (sns) {
      return "sns"
    }
  } else {
    return "pediatric"
  }
}

export const DownloadOverlay = (props) => {
  const zipInput = useRef(null)
  const [zip, setZip] = useState("")
  const [physFetching, setPhysFetching] = useState(false)
  const [downloaded, setDownloaded] = useState(false)
  const [latLng, setLatLng] = useState(null)
  const [physicians, setPhysicians] = useState([])

  // const fetchPhysicianData = () => {
  //   const zipcode = zipInput.current.value

  //   if (zipcode.length >= 5) {
  //     setPhysFetching(true)
  //     geolocateZipcode(zipcode)
  //       .then((latLng) => {
  //         setLatLng(latLng)
  //         return latLng
  //           ? fetchPhysicians(
  //               `?Lat=${latLng.lat}&Lng=${latLng.lng}&ItemCount=5&PageNumber1`
  //             )
  //           : new Promise((done) => done(false))
  //       })
  //       .then((response) => (response && response.Results) || [])
  //       .then((ph) => {
  //         const physicians = ph.map((p) => {
  //           const dist = Math.round(p.DistanceFromRequest)

  //           return {
  //             address: `${p.Address1}, ${p.City}, ${p.State} ${p.Zip}`,
  //             phone: p.Phone ? `${p.Phone}` : null,
  //             name: `${p.First} ${p.Last}, ${p.ProfessionalDesignation}`,
  //             distance: `${dist} mile${dist > 1 ? "s" : ""} away`,
  //             lat: p.Lat,
  //             lng: p.Lng,
  //           }
  //         })

  //         setPhysFetching(false)
  //         setPhysicians(physicians)
  //       })
  //   } else if (latLng) {
  //     setLatLng(null)
  //   }
  // }

  const generateGetParams = () => {
    let essScore
    let snsScore
    let chadScore
    let chad
    let ess
    let sns

    if (typeof window === "object") {
      essScore = storage.getObject("EssScore")
      snsScore = storage.getObject("SnsScore")
      chadScore = storage.getObject("ChadScore")
      chad = storage.getObject("chad")
      ess = storage.getObject("ess")
      sns = storage.getObject("sns")
    }

    return pdfGetParams({
      essScore,
      snsScore,
      chadScore,
      chad,
      ess,
      sns,
      physicians,
      latLng,
    })
  }

  const handleZipChange = () => {
    setZip(zipInput.current.value)
    //fetchPhysicianData()
  }

  const trackZipcodeOnSubmit = () => {
    if (zip.length >= 5) {
      gtmDataLayerPush({
        event: customEventTypes.formSubmit,
        gtmTrackingContext: "adultDownloadZip" + props.screenerType,
        value: {
          zipcodeScreener: zip,
        },
      })
    }
  }

  const continueBtnClicked = () => {
    trackZipcodeOnSubmit()
    setDownloaded(true)
  }

  const toggleModal = (e) => {
    downloaded && setDownloaded(false)
    setZip("")
    props.toggle(e)
  }

  const notDownloadedModal = (
    <Fragment>
      <ModalBody>
        <p className="text__size--small text__title m-0">
          Would you like a list of nearby sleep specialists?
          <span className="text__weight--base"> (Optional)</span>
        </p>
        <p className="text__size--small m-0">
          Results document will include up to 5 sleep specialists closest to
          you.
        </p>
        <div className="row mt-10">
          <div className="col-6 col-md-4 mt-10">
            <input
              type="text"
              ref={zipInput}
              className={`form-material__input ${
                zip !== "" ? "form-material__input--focus" : ""
              }`}
              name="download-zip-input"
              onChange={handleZipChange}
            />
            <span className="bar" />
            <label className="form-material__label pl-15">ZIP</label>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <TrackingWrapper
          gtmTrackId={"adultDownloadCancel" + props.screenerType}
        >
          <Button
            className="btn btn--step btn--cancel mr-25"
            onClick={(e) => toggleModal(e)}
          >
            CANCEL
          </Button>
        </TrackingWrapper>
        {!physFetching ? (
          <TrackingWrapper
            gtmTrackId={"adultDownloadContinue" + props.screenerType}
          >
            <a
              target="_blank"
              href={`/php/api/download_result.php?type=${getPDFType(
                props.adultDownload
              )}&${generateGetParams()}`}
              className="btn btn--primary btn--step mr-0"
              onClick={() => continueBtnClicked()}
            >
              CONTINUE
            </a>
          </TrackingWrapper>
        ) : (
          <button disabled className="btn btn--primary btn--step mr-0">
            CONTINUE
          </button>
        )}
      </ModalFooter>
    </Fragment>
  )

  const downloadedModal = (
    <Fragment>
      <ModalBody>
        <p className="text__size--small text__title m-0">
          Thanks! Your download should begin in a new window.
        </p>
        <hr className="w-100 mt-30" />
        <div className="mt-10">
          <p className="text__title text__title--2 text--brand-secondary">
            Your next step
          </p>
          {props.adultDownload ? (
            <p className="text__size--small text__title m-0">
              Discuss your scores with a sleep specialist, who can determine
              whether you should undergo narcolepsy testing.
            </p>
          ) : (
            <p className="text__size--small text__title m-0">
              Discuss your child's score with a sleep specialist, who can
              determine whether your child should undergo narcolepsy testing.
            </p>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="find-specialist col-12 col-md-8 mt-20 p-0">
          <TrackingWrapper
            gtmTrackId={"resultsDownloadFASS_" + props.screenerType}
          >
            <Link
              to={getPath("FindASleepSpecialist")}
              className="btn btn--primary w-sm-100"
            >
              Find a sleep specialist near you{" "}
              <i className="fa fa-angle-right ml-5" />
            </Link>
          </TrackingWrapper>
          <div className="close-text mt-30" onClick={(e) => toggleModal(e)}>
            Close window
          </div>
        </div>
      </ModalFooter>
    </Fragment>
  )

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={(e) => toggleModal(e)}
      centered={true}
      className={"modal-dialog-ess-download"}
    >
      <ModalHeader>
        <button type="button" className="close" aria-label="Close" onClick={(e) => props.toggle(e)}>
          <span aria-hidden="true">&times;</span>
        </button>
        <span className="text--body-copy-dark text__title text__title--3">
          Download Results
        </span>
      </ModalHeader>
      {downloaded ? downloadedModal : notDownloadedModal}
    </Modal>
  )
}

export const EmailOverlay = (props) => {
  const emailInput = useRef(null)
  const zipInput = useRef(null)
  const [zip, setZip] = useState("")
  const [fetching, setFetching] = useState(false)
  const [physFetching, setPhysFetching] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const [emailSentPed, setEmailSentPed] = useState(false)
  const [latLng, setLatLng] = useState(null)
  const [errors, setErrors] = useState({})
  const [physicians, setPhysicians] = useState([])
  const recaptcha = useRef(null)
  const [continueBtn, setcontinueBtn] = useState(true)

  const fetchPhysicianData = () => {
    const zipcode = zipInput.current.value

    if (zipcode.length >= 5) {
      setPhysFetching(true)
      geolocateZipcode(zipcode)
        .then((latLng) => {
          setLatLng(latLng)
          return latLng
            ? fetchPhysicians(
                `?Lat=${latLng.lat}&Lng=${latLng.lng}&ItemCount=5&PageNumber1`
              )
            : new Promise((done) => done(false))
        })
        .then((response) => (response && response.Results) || [])
        .then((ph) => {
          const physicians = ph.map((p) => {
            const dist = Math.round(p.DistanceFromRequest)

            return {
              address: `${p.Address1}, ${p.City}, ${p.State} ${p.Zip}`,
              phone: p.Phone ? `${p.Phone}` : null,
              name: `${p.First} ${p.Last}, ${p.ProfessionalDesignation}`,
              distance: `${dist} mile${dist > 1 ? "s" : ""} away`,
              lat: p.Lat,
              lng: p.Lng,
            }
          })

          setPhysFetching(false)
          setPhysicians(physicians)
        })
    } else if (latLng) {
      setLatLng(null)
    }
  }

  const handleZipChange = () => {
    setZip(zipInput.current.value)
    //fetchPhysicianData()
  }

  const trackZipcodeOnSubmit = () => {
    if (zip.length >= 5) {
      gtmDataLayerPush({
        event: customEventTypes.formSubmit,
        gtmTrackingContext: "adultEmailZip" + props.screenerType,
        value: {
          zipcodeScreener: zip,
        },
      })
    }
  }

  const validateFieldEmail = () => {
    let valid
    let errors = {}
    if (isEmpty(emailInput.current.value)) {
      errors = {
        emailInput: "empty",
      }
      valid = false
    } else if (!isValidEmail(emailInput.current.value)) {
      errors = {
        emailInput: "email-not-valid",
      }
      valid = false
    } else {
      valid = true
    }

    return new Promise((resolve) => {
      setErrors(errors)
      resolve(valid)
    })
  }

  const sendEmailResult = (e) => {
    e.preventDefault()

    let essScore = 10
    let snsScore = 8
    let chadScore = 10
    let chad = {}
    let ess = {}
    let sns = {}

    if (typeof window.localStorage === "object") {
      essScore = storage.getObject("EssScore")
      snsScore = storage.getObject("SnsScore")
      chadScore = storage.getObject("ChadScore")
      chad = storage.getObject("chad")
      ess = storage.getObject("ess")
      sns = storage.getObject("sns")
    }

    const emailAddress = emailInput.current.value

    validateFieldEmail().then((valid) => {
      const physData = {
        address: [],
        distance: [],
        lat: [],
        lng: [],
        name: [],
        phone: [],
      }

      physicians.forEach((p) =>
        Object.keys(p).forEach((k) => {
          physData[k].push(p[k])
        })
      )
      const requestDataRaw = {
        mlat: latLng && latLng.lat,
        mlng: latLng && latLng.lng,
        address: physData.address,
        distance: physData.distance,
        lat: physData.lat,
        lng: physData.lng,
        name: physData.name,
        phone: physData.phone,
        type: getPDFType(props.adultEmail),
        emailAddress,
        ess: objToArr(ess),
        sns: objToArr(sns),
        chad: objToArr(chad),
        essTot: essScore,
        snsTot: snsScore,
        chadTot: chadScore,
      }

      let requestData = {}

      for (let key in requestDataRaw) {
        if (requestDataRaw.hasOwnProperty(key)) {
          if (requestDataRaw[key] || requestDataRaw[key] === 0) {
            requestData[key] = requestDataRaw[key]
          }
        }
      }

      if (valid) {
        setFetching(true)

        gtmDataLayerPush({
          event: customEventTypes.formSubmit,
          gtmTrackingContext: "adultEmailContinue" + props.screenerType,
        })

        const pdfType = getPDFType(props.adultEmail)
        const queryParams = pdfGetParams({
          essScore,
          snsScore,
          chadScore,
          chad,
          ess,
          sns,
        })
        const pdfQuery = `?type=${pdfType}&${queryParams}`

        return sendmeResult(requestData, pdfQuery).then(() => {
          setFetching(false)
          trackZipcodeOnSubmit()
          if (pdfType === "pediatric") {
            setEmailSentPed(true)
          } else {
            setEmailSent(true)
          }
        })
      } else {
        return false
      }
    })
  }

  const toggleModal = (e) => {
    emailSent && setEmailSent(false)
    emailSentPed && setEmailSentPed(false)
    setZip("")
    props.toggle(e)
  }

  const enableContinueButton = () => {
    setcontinueBtn(false)
  }

  const disableContinueButton = () => {
    setcontinueBtn(true)
  }

  const notEmailedModal = (
    <Fragment>
      <ModalBody>
        <div className="col-12 p-0">
          <p className="text__size--small text__title m-0 pb-15">
            Enter an email address:
          </p>
          <FormMaterialInput ref={emailInput} label="Email Address*">
            <ErrorMessage
              show={errors.emailInput === "empty"}
              message="Please fill out this field."
            />
            <ErrorMessage
              show={errors.emailInput === "email-not-valid"}
              message="Your email address does not have a valid domain name."
            />
          </FormMaterialInput>
        </div>
        <p className="text__size--small text__title m-0">
          Would you like a list of nearby sleep specialists?
          <span className="text__weight--base"> (Optional)</span>
        </p>
        <p className="text__size--small m-0">
          Results document will include up to 5 sleep specialists closest to
          you.
        </p>
        <div className="row mt-10 mb-sm-30 mb-40">
          <div className="col-6 col-md-4 mt-10">
            <input
              type="text"
              ref={zipInput}
              className={`form-material__input ${
                zip !== "" ? "form-material__input--focus" : ""
              }`}
              name="download-zip-input"
              onChange={handleZipChange}
            />
            <span className="bar" />
            <label className="form-material__label pl-15">ZIP</label>
          </div>
        </div>
        <div className="email-recaptcha-container">
          <ReCAPTCHA
            sitekey={gooReClientKey}
            ref={recaptcha}
            onChange={enableContinueButton}
            onExpired={disableContinueButton}
            onErrored={disableContinueButton}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="buttons email-btns">
          <TrackingWrapper gtmTrackId={"adultEmailCancel" + props.screenerType}>
            <Button
              className="btn btn--step btn--cancel mr-25"
              onClick={(e) => toggleModal(e)}
            >
              CANCEL
            </Button>
          </TrackingWrapper>
          {!physFetching ? (
            <TrackingWrapper
              gtmTrackId={"adultEmailContinue" + props.screenerType}
            >
              <SubmitButtonLoading
                className="btn btn--primary btn--step mr-0"
                text="CONTINUE"
                type="submit"
                onClick={(e) => sendEmailResult(e)}
                loading={fetching}
                disabled={continueBtn}
              />
            </TrackingWrapper>
          ) : (
            <button disabled className="btn btn--primary btn--step mr-0">
              CONTINUE
            </button>
          )}
        </div>
        <div className="required mt-20">
          <span className="text__size--xsmall text__weight--base">
            *Required fields
          </span>
        </div>
      </ModalFooter>
    </Fragment>
  )

  const emailedModal = (pediatric) => (
    <Fragment>
      <ModalBody>
        <p className="text__size--small text__title m-0">
          Thanks! Your email has been sent.
        </p>
        <hr className="w-100 mt-30" />
        <div className="mt-10">
          <p className="text__title text__title--2 text--brand-secondary">
            Your next step
          </p>
          {pediatric ? (
            <p className="text__size--small text__title m-0">
              Discuss your child's score with a sleep specialist, who can
              determine whether your child should undergo narcolepsy testing.
            </p>
          ) : (
            <p className="text__size--small text__title m-0">
              Discuss your scores with a sleep specialist, who can determine
              whether you should undergo narcolepsy testing.
            </p>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="find-specialist col-12 col-md-8 mt-20 p-0">
          <TrackingWrapper
            gtmTrackId={"resultsEmailFASS_" + props.screenerType}
          >
            <Link
              to={getPath("FindASleepSpecialist")}
              className="btn btn--primary w-sm-100"
            >
              Find a sleep specialist near you{" "}
              <i className="fa fa-angle-right ml-5" />
            </Link>
          </TrackingWrapper>
          <div className="close-text mt-30" onClick={(e) => toggleModal(e)}>
            Close window
          </div>
        </div>
      </ModalFooter>
    </Fragment>
  )

  const getEmailSentType = (adultSent, pediatricSent) => {
    if (pediatricSent) {
      return emailedModal(true)
    } else if (adultSent) {
      return emailedModal(false)
    }
  }

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={(e) => toggleModal(e)}
      centered={true}
      className={"modal-dialog-email-scores"}
    >
      <ModalHeader>
       <button type="button" className="close" aria-label="Close" onClick={(e) => props.toggle(e)}>
          <span aria-hidden="true">&times;</span>
        </button>
        <span className="text--body-copy-dark text__title text__title--3">
          Email Results
        </span>
      </ModalHeader>
      {emailSent || emailSentPed
        ? getEmailSentType(emailSent, emailSentPed)
        : notEmailedModal}
    </Modal>
  )
}

export const YourNextSteps = () => (
  <Fragment>
    <div className="your-next-steps container bg--brand-muted-light mt-50 mt-sm-20 b-radius">
      <div className="bg--brand-muted-light pt-40 pb-40">
        <p className="text__title text__title--2 text--brand-secondary">
          Your next step
        </p>
        <p className="next-steps-text">
          You likely completed a screener because you experience at least 1
          symptom of narcolepsy. While the screeners provide useful information
          about your symptoms, they cannot determine whether you have
          narcolepsy. Your next step should be seeing a sleep specialist. Share
          your scores and work with the sleep specialist to decide what you
          should do next.
        </p>
        <div className="row mt-20">
          <div className="discuss-scores col-12 col-lg-7 mt-20">
            <p className="mb-0">
              Discuss your scores with a sleep specialist, who can determine
              whether you should undergo narcolepsy testing.
            </p>
          </div>
          <div className="find-specialist col-12 col-lg-5 mt-20 p-xs-10">
            <TrackingWrapper gtmTrackId="adultScreenersBottomFASS">
              <Link
                to={getPath("FindASleepSpecialist")}
                className="btn btn--primary w-sm-100"
              >
                Find a sleep specialist near you{" "}
                <i className="fa fa-angle-right ml-5" />
              </Link>
            </TrackingWrapper>
          </div>
        </div>
      </div>
    </div>
    <hr className="next-steps-hr" />
  </Fragment>
)
