import React, { Component, Fragment } from "react";
import { sendPhysDirections } from "@component-utility/requests";
import { win, withFormValidation, gmapsLoaded } from "@component-utility/utils";
import { autocompleteOpts } from "@config/map-styles";
import {customEventTypes, gtmDataLayerPush, gtmTrackingContexts} from "@component-utility/TrackingTools";
import config from "@config/config";

const gooReClientKey = config.RECAPTCHA_CLIENT;

export class StepDirections extends Component {
  constructor(props) {
    super(props);

    const { registerInput } = props;

    this.state = {
      errors: {},
      directionsErr: '',
      displayDirectionInputs: true,
      buttonState: "DRIVING",
      showEmailModal: false,
      messageSending: false,
      showMessageSent: false,
      btnDisabled: true
    };

    this.emailAddress = ""
    this.startingAddress = {}
    this.startingPoint = ""
    this.clearLocation = this.clearLocation.bind(this)
    this.onStartSubmit = this.onStartSubmit.bind(this)
    this.updateAddressInput = this.updateAddressInput.bind(this)
    this.updateInputFromAutocomplete = this.updateInputFromAutocomplete.bind(this)
    this.openEmailModal = this.openEmailModal.bind(this)
    this.updateInputValue = this.updateInputValue.bind(this)
    this.calculateAndDisplayRoute = this.calculateAndDisplayRoute.bind(this)
    this.emailDirections = this.emailDirections.bind(this)
    this.closeEmailModal = this.closeEmailModal.bind(this)
    this.printDirections = this.printDirections.bind(this)
    this.enableSendDirections = this.enableSendDirections.bind(this)
    this.disableSendDirections = this.disableSendDirections.bind(this)

    const fields = {
      email: React.createRef(),
      startingAddr: React.createRef()
    };
    const elements = {
      recaptcha: React.createRef()
    }
    this.elements = elements
    this.fields = fields;

    registerInput("email", fields.email, "email");
  }

  setupAutocomplete() {
    let input = document.getElementById("pac-input");

    const autocomplete = new window.google.maps.places.Autocomplete(
      input,
      autocompleteOpts
    );
  new window.google.maps.event.addListener(
      autocomplete,
      "place_changed",
      () => {
          this.updateInputFromAutocomplete(autocomplete)
      })
  }

  onStartSubmit(e) {
      e.preventDefault()
      const startingAddr  = this.fields.startingAddr.current

      startingAddr.blur()

      this.startingAddress = startingAddr.value

          this.geocodeLatLng(this.startingAddress).then((point) => {
          const evtType = customEventTypes.formSubmit,
              evtContext = gtmTrackingContexts.findASleepSpecialistDirections
              this.startingPoint = point
              this.calculateAndDisplayRoute("DRIVING")
              this.setState({'directionsErr': ''})
              gtmDataLayerPush({
                  event: evtType,
                  gtmTrackingContext: evtContext
              })
          }).catch(() => {
              const message = 'invalid'
              this.setState({'directionsErr': message})
              this.resetStart()
          })

  }

  calculateAndDisplayRoute(modeOfTransportation) {

          const {startingPoint} = this

          this.setState(
              {
                  displayDirectionInputs: false
              },
              () => {
                  let directionsService = new window.google.maps.DirectionsService();
                  let directionsDisplay = new window.google.maps.DirectionsRenderer();

                  const { doctor, mapDirections } = this.props

                  document.getElementById("right-panel").innerHTML = "";
                  directionsDisplay.setPanel(document.getElementById("right-panel"));


                  //this state update is for the buttons class but updating the state here is also what refreshes the view for the directions
                  this.setState({
                      buttonState: modeOfTransportation
                  });

                  let destination = {
                      location: {
                          lat: doctor.Lat,
                          lng: doctor.Lng
                      }
                  };
                  directionsService.route(
                      {
                          origin: startingPoint,
                          destination: destination,
                          travelMode: modeOfTransportation
                      },
                      function(response, status) {
                          if (status === "OK") {
                              directionsDisplay.setDirections(response);
                              // pass response up to parent
                              mapDirections(response, startingPoint);
                          } else {
                              //window.alert('Directions request failed due to ' + status)
                          }
                      }
                  );
              }
          );
  }

  clearLocation() {
    const {startingAddr} = this.fields
    this.setState(
      {
        displayDirectionInputs: true,
        startingAddress: ''
      },
      () => {
        this.setupAutocomplete();
        startingAddr.current.focus()
      }
    );
  }

  openEmailModal() {
    this.setState({
      showEmailModal: true
    });
  }


  updateInputValue(evt) {
    this.emailAddress = evt.target.value;
  }

  resetStart() {
      this.startingAddress = ""
      this.startingPoint = {}
  }

  updateInputFromAutocomplete(autocompleteObj) {
      const {formatted_address=""} = autocompleteObj.getPlace()
      this.startingAddress = formatted_address
  }

  updateAddressInput(event) {
      this.setState({startingAddress: event.target.value});
  }

  geocodeLatLng(address) {
      return new Promise((resolve, reject) => {
          if (win && gmapsLoaded()) {
              const geocoder = new win.google.maps.Geocoder()
              geocoder.geocode({'address': address}, (result, status) => {
                  if (status === 'OK') {
                      const {geometry} = result[0]
                      const point = {
                          location: {
                              lat: geometry.location.lat(),
                              lng: geometry.location.lng()
                          }
                      }
                      resolve(point);
                  } else {
                      reject(new Error('cannot find this address: ' + address));
                  }
              })
          }
      })
  }

  closeEmailModal() {
    this.setState({
      showEmailModal: false
    });
  }

  messageSending(itSent) {
    return itSent ? "message-sent-overlay" : "d-none";
  }

      printDirections() {
          const height = window.innerHeight
          const width = window.innerWidth
          let mywindow = window.open('', 'PRINT', `height=${height},width=${width}`)

          mywindow.document.write(
              '<html><head><link href="https://fonts.googleapis.com/css?family=Open+Sans" rel="stylesheet" type="text/css"><title>' +
              document.title +
              '</title></head><body><h1>' +
              document.title +
              '</h1>' +
              document.getElementById('right-panel').innerHTML +
              '</body></html>'
          )
          mywindow.document.body.setAttribute('style', 'font-family: "Open Sans", sans-serif !important')

          setTimeout(function() {
              mywindow.document.close() // necessary for IE >= 10
              mywindow.focus() // necessary for IE >= 10*/
          }, 50)

          return true
      }


  validate() {
    const { validate } = this.props;
    const errors = validate();
    const valid = Object.keys(errors).length === 0;
    return new Promise(resolve =>
      this.setState(() => ({ ...this.state, errors }), () => resolve(valid))
    );
  }

  emailData(emailData) {
    return new Promise(done =>
      this.setState(prevState => ({ ...prevState, emailData }), () => done())
    );
  }

  emailDirections() {
    let emailPostBody = {
      To: this.emailAddress,
      Directions: win && win.jQuery ? win.jQuery("#right-panel").html() : "",
      PhysicianId: this.props.doctor.Id
    };

    this.emailData(emailPostBody)
      .then(() => this.validate())
      .then(() => {
        if (Object.keys(this.state.errors).length === 0) {

          this.setState({ messageSending: true });
          return sendPhysDirections(emailPostBody).then(data => {
            if (data) {
              this.setState({
                showMessageSent: true
              });
              this.setState(
                {
                  messageSending: false,
                  showMessageSent: true
                },
                () => {
                  setTimeout(() => {
                    this.setState({
                      showMessageSent: false
                    });

                    this.closeEmailModal();
                  }, 3800);
                }
              );
            }
          });
        } else {
          return false;
        }
      })
      .then(done => {
        return this.setState({ messageSending: false });
      });
  }

  enableSendDirections() {
    this.setState({
      btnDisabled: false
    })
  }

  disableSendDirections() {
    this.setState({
      btnDisabled: true
    })
  }


  componentDidMount() {
    this.setupAutocomplete();
  }

  render() {
    const { displayDirectionInputs, showEmailModal, showMessageSent, buttonState, messageSending, directionsErr, errors, btnDisabled } = this.state
    const {
      startingAddress,
      elements: {recaptcha}
    } = this
    return (
      <Fragment></Fragment>
    );
  }
}

export default withFormValidation(StepDirections);
