import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Footer from '@component-structure/Footer';
import Header from '@component-structure/Header';
import config from '@config/config';
import { getPageByPath } from '@component-utility/pagelist';
import FontHack from '@component-utility/FontHack';

const createScriptNode = (content) => {
	const n = document.createElement('script');
	n.innerHTML = content;
	return n;
};

const createSrcScriptNode = (src) => {
	const n = document.createElement('script');
	n.setAttribute('src', src);
	return n;
};

const Layout = (props) => {

	useEffect(() => {
		setPageTitle();
		// includeGTM()

		if (
			props.location.pathname === '/find-a-narcolepsy-sleep-specialist/' ||
			props.location.pathname === '/physician-results/'
		) {
			includeGMaps();
		}
		checkIfLive();
		addJquery();
	});

	const setMeta = (name, content = '') => {
		let meta = document.querySelector(`meta[name=${name}]`);

		if (!meta) {
			meta = document.createElement('meta');
			meta.setAttribute('name', name);
		}

		meta.setAttribute('content', content);
		document.querySelector('head').appendChild(meta);
	};

	const setPageTitle = () => {
		const { pathname } = props.location;
		const pageObject = getPageByPath(pathname) || {};

		setMeta('description', pageObject.description);

		document.title = pageObject.title;
	};

	const checkIfLive = () => {
		const h = document.querySelector('head');
		const isLive = window.location.hostname.indexOf('www.morethantired.com') > -1;
		const styleTag = document.createElement('style');
		styleTag.append(FontHack);
		h.append(styleTag);
	};

	const includeGMaps = () => {
		const h = document.querySelector('head');
		const gmk = config.googleMapsKey;
		const src = `https://maps.googleapis.com/maps/api/js?key=${gmk}&libraries=places&callback=initMap`;
		console.log('Google Maps Keys: ', gmk);
		console.log('Source URL:', src);
		const fnd = document.querySelectorAll(`script[src="${src}"]`);

		if (!fnd.length) {
			h.appendChild(createSrcScriptNode(src));
		}
	};


	// const includeGTM = () => {
	// 	const h = document.querySelector("head")

	// 	const scr1 = `(window.dataLayer = [])`
	// 	const scr2 = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
	// 	new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
	// 	j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
	// 	'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
	// 	})(window,document,'script','dataLayer','GTM-WLFVQVZ');`

	// 	const oth = document.querySelectorAll("script")
	// 	const isScr1 = [...oth].filter(o => o.text === scr1).length

	// 	if (!isScr1) {
	// 		h.appendChild(createScriptNode(scr1))
	// 	}

	// 	const isScr2 = [...oth].filter(o => o.text === scr2).length
	// 	if (!isScr2) {
	// 		h.appendChild(createScriptNode(scr2))
	// 	}
	// }

	const addJquery = () => {
		const h = document.querySelector('head');
		const src = `/vendor/jquery-3.3.1.slim.min.js`;
		h.appendChild(createSrcScriptNode(src));
	};

	// const addBootstrap = () => {
	// 	const h = document.querySelector('head');
	// 	const src = `/vendor/bootstrap.min.js`;
	// 	h.appendChild(createSrcScriptNode(src));
	// };

	// const addPopper = () => {
	// 	const h = document.querySelector('head');
	// 	const src = `/vendor/popper.min.js`;
	// 	h.appendChild(createSrcScriptNode(src));
	// };

	const pageObject = getPageByPath(props.location.pathname) || {};
	const isNarcResourcePage = props.location.pathname === '/narcolepsy-resources/' // currently only scoped for this update 04/15/2024
	return (
		
		<>
			<Helmet>
				<link
					rel="canonical"
					href={`https://www.morethantired.com${props.location.pathname || ``}`}
				/>
				
				{isNarcResourcePage && pageObject.title && (
					<meta property="og:title" content={pageObject.title} />
				)}
				{isNarcResourcePage && pageObject.description && (
					<meta property="og:description" content={pageObject.description} />
				)}
			</Helmet>
			<div className='App'>
				<Header {...props} />
				<main tabIndex='-1' id='main'>
					{props.children}
				</main>
				<Footer {...props}/>
			</div>
		</>
	);
};

export default Layout;

Layout.propTypes = {
	children: PropTypes.node,
};

Layout.defaultProps = {
	children: null,
};
