import React, { Component } from "react"
import config from "@config/config"
import { sharePhysFinder } from "@component-utility/requests"
import { withFormValidation, ErrorMessage } from "@component-utility/utils"
import { ModalExternalLink } from "@component-structure//ModalExternalLink"
import {customEventTypes, gtmDataLayerPush, gtmTrackingContexts, TrackingWrapper} from "@component-utility/TrackingTools";


const gooReClientKey = config.RECAPTCHA_CLIENT
export class EmailFinder extends Component {
  constructor(props) {
    super(props)

    const { registerInput } = props

    this.state = {
      errors: {},
      showShareModal: false,
      showMessageSent: false,
      emailSubmitting: false,
      fromEmail: "",
      fromName: "",
      toEmail: "",
      toName: "",
      breakpoint: null,
      disabled: true
    }

    const fields = {
      toName: React.createRef(),
      toEmail: React.createRef(),
      fromName: React.createRef(),
      fromEmail: React.createRef()
    }
    const elements = {
      recaptcha: React.createRef()
    }
    this.elements = elements
    this.fields = fields

    registerInput("toName", fields.toName, "empty")
    registerInput("fromName", fields.fromName, "empty")
    registerInput("toEmail", fields.toEmail, "email")
    registerInput("fromEmail", fields.fromEmail, "email")

    this.openShareModal = this.openShareModal.bind(this)
    this.closeShareModal = this.closeShareModal.bind(this)
    this.updateInputValue = this.updateInputValue.bind(this)
    this.sharePhysicianFinder = this.sharePhysicianFinder.bind(this)
    this.enableSendInfo = this.enableSendInfo.bind(this)
    this.disableSendInfo = this.disableSendInfo.bind(this)
  }

  handleFocus(e) {
    e.target.classList.add("focused-input-email")
  }

  handleBlur(e) {
    e.target.classList.remove("focused-input-email")
  }

  messageSending(itSent) {
    return itSent ? "message-sent-overlay" : "d-none"
  }

  openShareModal() {
    this.setState({
      showShareModal: true
    })
  }

  saveUserData(userData) {
    return new Promise(done =>
      this.setState(prevState => ({ ...prevState, userData }), () => done())
    )
  }

  validate() {
    const { validate } = this.props
    const errors = validate()
    const valid = Object.keys(errors).length === 0
    return new Promise(resolve =>
      this.setState(() => ({ ...this.state, errors }), () => resolve(valid))
    )
  }

  emailData(emailData) {
    return new Promise(done =>
      this.setState(prevState => ({ ...prevState, emailData }), () => done())
    )
  }

  sharePhysicianFinder(event) {
    event.preventDefault()

    let emailPostBody = {
      From: this.state.fromEmail.toLowerCase(),
      To: this.state.toEmail.toLowerCase(),
      ToName: this.state.toName,
      FromName: this.state.fromName,
      Subject: "",
      Message: "",
      ServiceKey: ""
    }

    this.emailData(emailPostBody)
      .then(() => this.validate())
      .then(() => {
        if (Object.keys(this.state.errors).length === 0) {
          const evtType = customEventTypes.formSubmit,
              context = gtmTrackingContexts.findASleepSpecialistShare
            gtmDataLayerPush({
                event: evtType,
                gtmTrackingContext: context
            })
          this.setState({ emailSubmitting: true })
          return sharePhysFinder(emailPostBody)
            .then(res => res)
            .then(data => {
              if (data) {
                this.setState(
                  {
                    showMessageSent: true,
                    emailSubmitting: false,
                    disabled: true
                  },
                  () => {
                    setTimeout(() => {
                      this.setState({
                        showMessageSent: false
                      })
                      this.closeShareModal()
                    }, 3800)
                  }
                )
              }
            })
        } else {
          return false
        }
      })
      .then(done => {
        return this.setState({ emailSubmitting: false })
      })
  }

  updateInputValue(evt) {
    this.setState({
      [evt.target.id]: evt.target.value
    })
  }

  closeShareModal() {
    this.setState({
      showShareModal: false,
      fromEmail: "",
      fromName: "",
      toEmail: "",
      toName: "",
      errors: {}
    })
  }

  fakeSubmit(e, fn) {
    e.preventDefault()
    if (typeof fn === "function") {
      fn()
    }
  }


  enableSendInfo() {
    this.setState({
      disabled: false
    })
  }

  disableSendInfo() {
    this.setState({
      disabled: true
    })
  }

  render() {
    const {gtmTrackContext} = this.props
    const context = gtmTrackContext === 'FASSHome' ? '' : gtmTrackContext
    const {
      elements: {recaptcha}
    } = this
    return (
      <div>
        <div className="email-finder-component">
          <div className="grey-background" style={{display:'none'}} onClick={this.openShareModal} />
          <div className="primary-container email-finder-wrapper">
              <div
                  className="share-finder email-finder-area col-12 col-sm-7 col-md-6 col-lg-4"
                  onClick={this.openShareModal}
              >
                  <div className="share-finder-content">
                    {/*
                    <TrackingWrapper gtmTrackId={"FASSShareTool" + context}>
                    <div className="email-icon">
                      <img src={mail} alt="envelope icon" />
                    </div>
                    <p className="link-text">
                      SHARE THE "FIND A SLEEP SPECIALIST"{" "}
                      <span className="d-block m-0">
                      {" "}
                        TOOL
                      <span>
                        <i className="fa fa-chevron-right" />
                      </span>
                    </span>
                    </p>
                    </TrackingWrapper>
                  */}
                  </div>
              </div>
            <div className="join-directory email-finder-area col-12 col-sm-5 col-md-6 col-lg-3">
              <div className="join-directory-content text--body-copy-dark">
                <h5>
                  If you are a healthcare professional specializing in sleep
                  medicine and would like to join our directory, visit
                </h5>
                <ModalExternalLink
                  link={`${config.narcolepsyLink}${
                    config.joinPhysDirectoryPath
                  }`}
                  customClassName="btn--none--find"
                >
                  <TrackingWrapper gtmTrackId={"FASSJoinNL" + context}>
                    <span className="text__link--underline text__weight--bold">
                      Narcolepsy Link
                    </span>
                  </TrackingWrapper>
                </ModalExternalLink>
                <span className="text__weight--bold">.</span>
              </div>
            </div>
            <div className="disclaimer email-finder-area col-12 col-lg-5">
              <p>
                The Find a Sleep Specialist tool was developed using
                third-party, objective, publicly available diagnosis claims
                data. Jazz Pharmaceuticals does not endorse any of these
                physicians and is not responsible for medical outcomes resulting
                from treatment by these physicians. Most, but not all,
                professionals in this tool are board certified in sleep
                medicine. The information included on this website is intended
                for residents of the United States only.
              </p>
            </div>
          </div>
          {/*
          <Modal
            className="modal-dialog-centered content-modal ss-share-modal"
            isOpen={this.state.showShareModal}
            onClosed={this.handleClose}
          >
           
            <ModalHeader toggle={this.closeShareModal}>
              <p className="text__title text__title--3">
                Share the "Find a Sleep Specialist" tool
              </p>
            </ModalHeader>
            <form
              className="action"
              onSubmit={this.sharePhysicianFinder}
              noValidate
            >
              <ModalBody>
                <p className="text__size--small">
                  Would you like to share this tool with someone?
                  <br className="hidden-xs-down"/><span className="hidden-sm-up">{" "}</span>
                  Email a link by completing the requested information.
                </p>
                <div className="body-content ss-share-modal-content mt-20">
                  <div
                    className={this.messageSending(this.state.showMessageSent)}
                  >
                    <img src={airplane} alt="message sent icon" />
                    Message Sent
                  </div>
                  <div className="row">
                    <div className="col-12 text__title text__size--small mb-10 mt-10 text--body-copy-dark">Recipient’s information</div>
                    <div className="form-group col-12 col-sm-6">
                      <input
                        type="text"
                        className={`form-material__input ${
                          this.state.toName !== "" ? "form-material__input--focus" : ""
                        }`}
                        id="toName"
                        value={this.state.toName}
                        onChange={this.updateInputValue}
                        onFocus={e => this.handleFocus(e)}
                        onBlur={e => this.handleBlur(e)}
                        ref={this.fields.toName}
                      />
                      <label className="form-material__label pl-15">Recipient's Name</label>
                      <ErrorMessage
                        show={this.state.errors.toName === "empty"}
                        message="Please fill out this field."
                      />
                    </div>
                    <div className="form-group col-12 col-sm-6">
                      <input
                        type="email"
                        className={`form-material__input ${
                          this.state.toEmail !== "" ? "form-material__input--focus" : ""
                        }`}
                        id="toEmail"
                        value={this.state.toEmail}
                        onChange={this.updateInputValue}
                        onFocus={e => this.handleFocus(e)}
                        onBlur={e => this.handleBlur(e)}
                        ref={this.fields.toEmail}
                      />
                      <label className="form-material__label pl-15">Recipient's Email Address</label>
                      <ErrorMessage
                        show={this.state.errors.toEmail === "empty"}
                        message="Please fill out this field."
                      />
                      <ErrorMessage
                        show={this.state.errors.toEmail === "email-not-valid"}
                        message="Your email address does not have a valid domain name."
                      />
                    </div>
                    <div className="col-12 text__title text__size--small mb-10 mt-md-10 text--body-copy-dark">Your information</div>
                    <div className="form-group col-12 col-sm-6">
                    <input
                        type="text"
                        className={`form-material__input ${
                          this.state.fromName !== "" ? "form-material__input--focus" : ""
                        }`}
                        id="fromName"
                        value={this.state.fromName}
                        onChange={this.updateInputValue}
                        onFocus={e => this.handleFocus(e)}
                        onBlur={e => this.handleBlur(e)}
                        ref={this.fields.fromName}
                      />
                      <label className="form-material__label pl-15">Name</label>
                      <ErrorMessage
                        show={this.state.errors.fromName === "empty"}
                        message="Please fill out this field."
                      />
                    </div>
                    <div className="form-group col-12 col-sm-6">
                      <input
                        type="email"
                        className={`form-material__input ${
                          this.state.fromEmail !== "" ? "form-material__input--focus" : ""
                        }`}
                        id="fromEmail"
                        value={this.state.fromEmail}
                        onChange={this.updateInputValue}
                        onFocus={e => this.handleFocus(e)}
                        onBlur={e => this.handleBlur(e)}
                        ref={this.fields.fromEmail}
                      />
                      <label className="form-material__label pl-15">Your Email Address</label>
                      <ErrorMessage
                        show={this.state.errors.fromEmail === "empty"}
                        message="Please fill out this field."
                      />
                      <ErrorMessage
                        show={this.state.errors.fromEmail === "email-not-valid"}
                        message="Your email address does not have a valid domain name."
                      />
                    </div>
                  </div>
                </div>
                <div className="recaptcha-container">
                  <ReCAPTCHA sitekey={gooReClientKey} ref={recaptcha} onChange={this.enableSendInfo} onExpired={this.disableSendInfo} onErrored={this.disableSendInfo} />
                  <ErrorMessage show={this.state.errors.recaptcha === "empty"} message="Please fill out this field." />
                </div>
              </ModalBody>
              <ModalFooter className="justify-content-center flex-column flex-sm-row align-items-stretch w-75 m-auto modal-footer pt-0">
                <SubmitButtonLoading
                  type="submit"
                  id="send-info"
                  className="btn btn--primary send-button mt-15 ml-20 ml-xs-0 mr-xs-0 order-sm-1"
                  loading={this.state.emailSubmitting}
                  text="Send Information"
                  disabled={this.state.disabled}
                />
                <button
                  className="btn btn--cancel no mt-15 order-sm-0 ml-xs-0 mr-xs-0"
                  onClick={e => {
                    this.fakeSubmit(e, this.closeShareModal)
                  }}
                >
                  Cancel
                </button>
              </ModalFooter>
            </form>
          </Modal>
                */}
        </div>
      </div>
    )
  }
}

export default withFormValidation(EmailFinder)
