import React, { Component, Fragment } from "react";
import { sharePhysicianInfo } from "@component-utility/requests";
import { withFormValidation, ErrorMessage } from "@component-utility/utils";
import {customEventTypes, gtmDataLayerPush, gtmTrackingContexts} from "@component-utility/TrackingTools";


export class EmailPhysicianDetails extends Component {
  constructor(props) {
    super(props);
    const { registerInput } = props;

    this.state = {
      errors: {},
      toRecipient: false,
      ccMe: false,
      showErrorModal: false,
      emailSubmitting: false,
      showMessageSent: false,
      disabled: true
    };

    this.handleCloseError = this.handleCloseError.bind(this);
    this.emailDetails = this.emailDetails.bind(this);
    this.enableSendInfo = this.enableSendInfo.bind(this);

    const fields = {
      fromEmail: React.createRef(),
      fromName: React.createRef(),
      toEmail: React.createRef(),
      toName: React.createRef()
    };
    const elements = {
      recaptcha: React.createRef()
    }
    this.elements = elements
    this.fields = fields;

    registerInput("fromEmail", fields.fromEmail, "email");
    registerInput("toEmail", fields.toEmail, "email");
    registerInput("fromName", fields.fromName, "empty");
    registerInput("toName", fields.toName, "empty");
  }

  handleCloseError() {
    this.setState({
      showErrorModal: false
    });
  }

  messageSending(itSent) {
    return itSent ? "message-sent-overlay" : "d-none";
  }

  expandedMessageSending(isExpanded) {
    return isExpanded ? { height: 300 } : "";
  }

  showRecipientEmailFields() {
    this.setState({ toRecipient: !this.state.toRecipient });
  }

  getErrorsFormatted(errors) {
    if (errors) {
      if (typeof errors === "string") {
        return <p className="errors">{errors}</p>;
      } else {
        return (
          <ul className="errors">
            {Object.keys(errors).map((value, index) => {
              return <li key={index}>{errors[value]}</li>;
            })}
          </ul>
        );
      }
    }
  }

  selectCCMe() {
    this.setState({
      ccMe: true
    });
  }

  validate() {
    const { validate } = this.props;
    const errors = validate();
    const valid = Object.keys(errors).length === 0;
    return new Promise(resolve =>
      this.setState(() => ({ ...this.state, errors }), () => resolve(valid))
    );
  }

  emailData(emailData) {
    return new Promise(done =>
      this.setState(prevState => ({ ...prevState, emailData }), () => done())
    );
  }

  enableSendInfo() {
    this.setState({
      disabled: false
    })
  }

  disableSendInfo() {
    this.setState({
      disabled: true
    })
  }

  emailDetails(event) {
    event.preventDefault();
    let emailPostBody = this.state.toRecipient
      ? {
          From: document.getElementById("your-email").value.toLowerCase(),
          To: document.getElementById("recipient-email").value.toLowerCase(),
          ToName: document.getElementById("recipient-name").value,
          FromName: document.getElementById("your-name").value,
          CC: this.state.ccMe,
          PhysicianId: this.props.doctor.Id,
          Subject: "",
          Message: "",
          ServiceKey: ""
        }
      : {
          From: document.getElementById("your-email").value.toLowerCase(),
          To: document.getElementById("your-email").value.toLowerCase(),
          ToName: "",
          FromName: "",
          CC: this.state.ccMe,
          PhysicianId: this.props.doctor.Id,
          Subject: "",
          Message: "",
          ServiceKey: ""
        };

    this.emailData(emailPostBody)
      .then(() => this.validate())
      .then(() => {
        if (Object.keys(this.state.errors).length === 0) {
          const evtType = customEventTypes.formSubmit,
                context = gtmTrackingContexts.findASleepSpecialistShareDetail
          gtmDataLayerPush({
              event: evtType,
              gtmTrackingContext: context
          })
          this.setState({ emailSubmitting: true });
          return sharePhysicianInfo(emailPostBody).then(data => {
            if (data) {
              this.setState({
                showMessageSent: true,
                disabled: true
              });
              this.setState(
                {
                  emailSubmitting: false,
                  showMessageSent: true
                },
                () => {
                  setTimeout(() => {
                    this.setState({
                      showMessageSent: false
                    });
                  }, 3800);
                }
              );
              window.grecaptcha.reset()
            }
          });
        } else {
          return false;
        }
      })
      .then(done => {
        return this.setState({ emailSubmitting: false });
      });
  }

  render() {
    const {
      elements: {recaptcha}
    } = this
    return (
      <div className="share-physician-info share-directions">
        {/*
        <h6 className="details-sub">Email this information:</h6>
        <div
          className={this.messageSending(this.state.showMessageSent)}
          style={this.state.toRecipient ? { height: 410 } : {}}
        >
          <img src={airplane} alt="airplane" />
          Message Sent
        </div>
        <form>
          <div className="colleague-checkbox">
            <input
              type="checkbox"
              id="send-to-colleague"
              className="email-styled-checkbox"
              onChange={() => this.showRecipientEmailFields()}
            />
            <label htmlFor="send-to-colleague" className="email-check-style" />
            <label>Send this information to someone</label>
          </div>
          <FormMaterialInput ref={this.fields.fromEmail} label="Your Email" id="your-email" additionalDivClassName="first-item" type="email" name="fromEmail">
            <ErrorMessage
              show={this.state.errors.fromEmail === "empty"}
              message="Please fill out this field."
            />
            <ErrorMessage
              show={this.state.errors.fromEmail === "email-not-valid"}
              message="Your email address does not have a valid domain name."
            />
          </FormMaterialInput>
          {this.state.toRecipient ? (
            <Fragment>
              <FormMaterialInput ref={this.fields.fromName} label="Your Name" id="your-name" name="fromName">
                <ErrorMessage
                  show={this.state.errors.fromName === "empty"}
                  message="Please fill out this field."
                />
              </FormMaterialInput>
              <FormMaterialInput ref={this.fields.toEmail} label="Recipient's Email" id="recipient-email" type="email" name="toEmail">
                <ErrorMessage
                  show={this.state.errors.toEmail === "empty"}
                  message="Please fill out this field."
                />
                <ErrorMessage
                  show={this.state.errors.toEmail === "email-not-valid"}
                  message="Your email address does not have a valid domain name."
                />
              </FormMaterialInput>
              <FormMaterialInput ref={this.fields.toName} label="Recipient's Name" id="recipient-name" name="toName">
                <ErrorMessage
                  show={this.state.errors.toName === "empty"}
                  message="Please fill out this field."
                />
              </FormMaterialInput>
              <div className="colleague-checkbox">
                <input
                  type="checkbox"
                  id="email-checkbox"
                  className="email-styled-checkbox"
                  onChange={() => this.selectCCMe()}
                />
                <label htmlFor="email-checkbox" className="email-check-style" />
                <label htmlFor="cc-me">CC me</label>
              </div>
            </Fragment>
          ) : null}
          <div className="recaptcha-container">
            <ReCAPTCHA sitekey={gooReClientKey} ref={recaptcha} onChange={this.enableSendInfo} onExpired={this.disableSendInfo} onErrored={this.disableSendInfo} />
            <ErrorMessage show={this.state.errors.recaptcha === "empty"} message="Please fill out this field." />
          </div>
          <SubmitButtonLoading
            id="send-info"
            className="btn btn--primary mt-10"
            type="submit"
            onClick={this.emailDetails}
            loading={this.state.emailSubmitting}
            disabled={this.state.disabled}
          />
        </form>
        <Modal isOpen={this.state.showErrorModal}>
          <div className="body-content">
            <h2 className="title">Error</h2>
            <p className="content">
              A server error occurred while submitting your request.
            </p>
            {this.getErrorsFormatted(this.state.errors)}
            <div className="action">
              <button
                className="btn btn--primary mt-10"
                onClick={this.handleCloseError}
              >
                Ok
              </button>
            </div>
          </div>
        </Modal>
          */}
      </div>
    );
  }
}

export default withFormValidation(EmailPhysicianDetails);
