import React, { Component } from "react"
import PropTypes from "prop-types"
import { win, gmapsLoaded } from "@component-utility/utils"
import MapStyles, { mapLabelConf } from "@config/map-styles"
import marker from "@images/icon/map-point0.png"

export class MapContainer extends Component {
  constructor(props) {
    super(props)

    this.markers = []
    this.label = mapLabelConf
    this.firstSearchComplete = false
  }

  componentDidMount() {
    if (win && gmapsLoaded()) {
      this.initMap()
    } else {
      window.initMap = this.initMap.bind(this)
    }
  }

  initMap() {
    this.map = new window.google.maps.Map(document.getElementById("map"), {
      center: window.google.maps.LatLng({ lat: 43.5117178, lng: -116.36588690000002 }),
      zoom: 0,
      mapTypeId: "roadmap",
      styles: MapStyles,
      gestureHandling: 'greedy'
    })

    this.directionsDisplay = new window.google.maps.DirectionsRenderer()

    if (window.innerWidth < 768) {
      new window.google.maps.event.addDomListenerOnce(this.map, 'dragstart', () => {
        new window.google.maps.event.addListener(this.map, 'dragend', () => {
          let newBounds = {
            east: this.map.getBounds().getNorthEast().lng(),
            north: this.map.getBounds().getNorthEast().lat(),
            south: this.map.getBounds().getSouthWest().lat(),
            west: this.map.getBounds().getSouthWest().lng(),
            center: this.map.getBounds().getCenter()
          }
          if (this.firstSearchComplete) {
            this.props.mapBoundsMoved(newBounds)
          }
        })

        new window.google.maps.event.addListener(this.map, 'zoom_changed', () => {
          let newBounds = {
            east: this.map.getBounds().getNorthEast().lng(),
            north: this.map.getBounds().getNorthEast().lat(),
            south: this.map.getBounds().getSouthWest().lat(),
            west: this.map.getBounds().getSouthWest().lng(),
            center: this.map.getBounds().getCenter()
          }
          if (this.firstSearchComplete) {
            this.props.mapBoundsMoved(newBounds)
          }
        })
      })
    } else {
      new window.google.maps.event.addDomListenerOnce(this.map, 'mouseover', () => {
        new window.google.maps.event.addListener(this.map, 'dragend', () => {
          let newBounds = {
            east: this.map.getBounds().getNorthEast().lng(),
            north: this.map.getBounds().getNorthEast().lat(),
            south: this.map.getBounds().getSouthWest().lat(),
            west: this.map.getBounds().getSouthWest().lng(),
            center: this.map.getBounds().getCenter()
          }
          if (this.firstSearchComplete) {
            this.props.mapBoundsMoved(newBounds)
          }
        })

        new window.google.maps.event.addListener(this.map, 'zoom_changed', () => {
          let newBounds = {
            east: this.map.getBounds().getNorthEast().lng(),
            north: this.map.getBounds().getNorthEast().lat(),
            south: this.map.getBounds().getSouthWest().lat(),
            west: this.map.getBounds().getSouthWest().lng(),
            center: this.map.getBounds().getCenter()
          }
          if (this.firstSearchComplete) {
            this.props.mapBoundsMoved(newBounds)
          }
        })
      })
    }




  }

  render() {
    const { bounds, removeMarkersNeeded, mapData, showModal, firstSearch, redoPhysicianData } = this.props

    if (removeMarkersNeeded) {
      this.removeMarkers()
    }

    if (mapData.length > 0 && mapData[0].markerNumber) {
      this.icon =
        win && gmapsLoaded()
          ? {
              url: marker,
              size: new window.google.maps.Size(41, 65),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(19, 65),
              labelOrigin: new window.google.maps.Point(20, 20)
            }
          : null
      this.addMarkers()
    }

    if (this.directionsDisplay) {
      this.setOrRemoveDirections()
    }

    if (bounds && !showModal) {
      if (bounds.center) {
        delete bounds.center
      }
      if(firstSearch) {
        this.map.fitBounds(bounds)
        this.firstSearchComplete = true
      } else if (!redoPhysicianData) {
        // this is where the logic for what ever we are going to do with 0 redo search results will go
      } else {
        this.addMarkers()
      }
    }

    return (
      <div id="map" />
    )
  }

  addMarkers() {
    const { mapData, clearMapDirections } = this.props
    const { label, icon, map, markers } = this
    for (let i = 0; i < mapData.length; i++) {
      const markerInfo = mapData[i],
        markerText = this.generateDoctorInfo(markerInfo)

      if (clearMapDirections) {
        label.text = markerInfo.markerNumber
      } else {
        label.text = "B"
      }

      const infoWindow = new window.google.maps.InfoWindow({
        content: markerText
      })

      markers.push(
        new window.google.maps.Marker({
          map: map,
          icon: icon,
          position: { lat: markerInfo.Lat, lng: markerInfo.Lng },
          label: label,
          zIndex: i
        })
      )

      markers[i].addListener("click", () => {
        infoWindow.open(map, markers[i])
      })
    }
  }

  generateDoctorInfo(infoObj) {
    const {
      First = "FirstName",
      Last = "Lastname",
      ProfessionalDesignation = "",
      Address1 = "",
      Phone,
      City,
      State,
      Zip
    } = infoObj

    const phoneInclusion = Phone
      ? '<b><span style="display:inline-block">' + Phone + "</span></b>"
      : ""

    return (
      "<div><b>" +
      First +
      " " +
      Last +
      ", " +
      ProfessionalDesignation +
      "</b></br>" +
      Address1 +
      "</br>" +
      City +
      ", " +
      State +
      ' <span style="display:inline-block">' +
      Zip +
      "</span></br>" +
      phoneInclusion +
      "</div>"
    )
  }

  removeMarkers() {
    for (let marker of this.markers) {
      marker.setMap(null)
    }
    this.markers.length = 0
  }

  setOrRemoveDirections() {
    const { clearMapDirections, startingLocation } = this.props
    const { directionsDisplay, markers, label, map, icon } = this

    if (clearMapDirections) {
      directionsDisplay.setMap(null)
    }

    if (!clearMapDirections && startingLocation) {
      directionsDisplay.setOptions({ suppressMarkers: true })
      directionsDisplay.setMap(this.map)
      directionsDisplay.setDirections(this.props.mapDirections)

      label.text = "A"

      markers.push(
        new window.google.maps.Marker({
          map: map,
          icon: icon,
          position: {
            lat: startingLocation.location.lat,
            lng: startingLocation.location.lng
          },
          label: label
        })
      )
    }
  }
}

MapContainer.propTypes = {
  mapData: PropTypes.array,
  bounds: PropTypes.object,
  displayPhysicianDetails: PropTypes.bool,
  removeMarkersNeeded: PropTypes.bool,
  mapDirections: PropTypes.object,
  clearMapDirections: PropTypes.bool,
  startingLocation: PropTypes.object,
  mapBoundsMoved: PropTypes.func,
  showModal: PropTypes.bool,
  firstSearch: PropTypes.bool,
  redoPhysicianData: PropTypes.array
}

export default MapContainer
