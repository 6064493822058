import axios from "axios"
import config from "@config/config"

const baseParams = {
  mode: "no-cors",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
}

const decorateURLPayload = origData => {
  return Object.assign({}, origData, { URL: config.apiBaseUrl })
}

const handle500 = () => {
  if (window.location.pathname !== "/500") {
    window.location.replace("/500")
  }
}

const request = (url, params = {}) =>
axios(url, { ...baseParams, ...params }).catch(error => {
  if (error.response.status === 500) {
    handle500()
  }
  return new Promise((_, reject) => reject(error))
})


export const sendmeResult = (data, queryString) =>
  request(`/php/api/download_result.php${queryString}`, {
    method: "POST",
    data: JSON.stringify(data)
  })
    .then(({ data }) => data.valid)
    .catch(() => false)

export const subscribe = data => {
  return request(`${config.apiRegister}/api/register`, {
    method: "POST",
    mode: "no-cors",
    data: JSON.stringify(data)
  })
    .then(({ data }) => data)
    .catch(function() {
      return false
    })
}

export const fetchPhysicians = queryString =>
  request(`/php/api/physician-finder.php/RecordSearch` + queryString, {
    method: "GET",
    mode: "no-cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
    .then(({ data }) => data)
    .catch(function() {
      return false
    })

export const sharePhysFinder = data => {
  return request(`/php/api/physician-finder.php/ShareSite`, {
    method: "POST",
    mode: "no-cors",
    data: JSON.stringify(decorateURLPayload(data))
  })
    .then(({ data }) => data)
    .catch(function() {
      return false
    })
}

export const sharePhysicianInfo = data => {
  return request(`/php/api/physician-finder.php/SharePhysician`, {
    method: "POST",
    mode: "no-cors",
    data: JSON.stringify(decorateURLPayload(data))
  })
    .then(({ data }) => data)
    .catch(function() {
      return false
    })
}

export const sendPhysDirections = data => {
  return request(`/php/api/physician-finder.php/SendDirections`, {
    method: "POST",
    mode: "no-cors",
    data: JSON.stringify(decorateURLPayload(data))
  })
    .then(({ data }) => data)
    .catch(function() {
      return false
    })
}

export const geolocateZipcode = zipcode => {
  if (window) {
    const geocoder = new window.google.maps.Geocoder()

    return new Promise((done, reject) => {
      geocoder.geocode(
        {
          address: zipcode
        },
        (geoData, geoStatus) => {
          if (geoStatus === window.google.maps.GeocoderStatus.OK) {
            const loc = geoData[0].geometry.location
            done({
              lat: loc.lat(),
              lng: loc.lng()
            })
          } else {
            reject(false)
          }
        }
      )
    })
  }
}