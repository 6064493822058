import React, { Component } from 'react';

import EmailFinderComponent from '@component-utility/EmailFinder';
import SearchBoxContainer from '@component-utility/SearchBoxPhysicians';

class FindASleepSpecialist extends Component {
	render() {
		return (
			<div className='find-sleep-specialist'>
				<div className='main'>
					<div className='search-section col-lg-7 text--body-copy-dark'>
						<div className='sleep-specialist-header'>
							<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
								Find a Sleep Specialist Near You in 3 Simple Steps
							</h1>
						</div>
						<ol className='search-steps'>
							<li className='list-item-objects'>
								<div className='list-number'>1</div>
								<div>
									Enter your location to get a list of sleep specialists
									near you
								</div>
							</li>
							<li className='list-item-objects'>
								<div className='list-number'>2</div>
								<div>
									Select a conveniently located specialist from the
									results
								</div>
							</li>
							<li className='list-item-objects'>
								<div className='list-number'>3</div>
								<div>Set up an appointment</div>
							</li>
						</ol>
						<p className='mt-2'>
							If you’re having trouble staying awake during the day or
							experiencing other sleep-related problems that you’d like to
							discuss with a sleep specialist, use this tool to help you
							find a sleep specialist in your area with expertise in
							sleep-related medical conditions.
						</p>
						<div className='search-wrapper'>
							<div className='search-input-section'>
								<SearchBoxContainer />
								<label>United States Only</label>
							</div>
						</div>
					</div>
					<div className='sleep-specialist-stats col-xs-12 col-lg-5'>
						<div className='image-wrapper'></div>
					</div>
				</div>
				<EmailFinderComponent
					key='email-finder-intro'
					gtmTrackContext='FASSHome'
				/>
			</div>
		);
	}
}

export default FindASleepSpecialist;
