import React, { Component, Fragment } from "react"
import { Link } from "gatsby"
import { getPath } from "@component-utility/pagelist"
import { pdfGetParams, win } from "@component-utility/utils"
import EssScale, { SnsScale, ChadScale } from "@component-utility/ScreenerElements"
import printIcon from "@images/icon/print-icon-purple.svg"
import { fetchPhysicians, getApiBasePath } from "@component-utility/requests"
import calendarIcon from "@images/icon/calendar-icon-green.svg"
import { TrackingWrapper } from "@component-utility/TrackingTools"
import { storage } from "@component-utility/utils"

export class AppointmentResults extends Component {
  constructor() {
    super()
    this.EssScores = JSON.parse(localStorage.getItem("EssScore"))
    this.SnsScores = JSON.parse(localStorage.getItem("SnsScore"))
    this.SnsScore = parseInt(localStorage.getItem("SnsScore"), 10)
    this.EssScore = parseInt(localStorage.getItem("EssScore"), 10)
    this.ChadScore = parseInt(localStorage.getItem("ChadScore"), 10)
    this.zipCode = parseInt(localStorage.getItem("placeName"), 10)

    this.ess = JSON.parse(localStorage.getItem("ess")) || {}
    this.sns = JSON.parse(localStorage.getItem("sns")) || {}
    this.chad = JSON.parse(localStorage.getItem("chad")) || {}

    this.essTestAvl = !isNaN(this.EssScore)
    this.snsTestAvl = !isNaN(this.SnsScore)
    this.chadTestAvl = !isNaN(this.ChadScore)
    this.printResults = this.printResults.bind(this)

    this.lat = win ? localStorage.getItem("lat") : ""
    this.lng = win ? localStorage.getItem("lng") : ""

    this.queryParams = {}
    this.state = { physFetching: false, physicians: [] }
  }

  fetchPhysicianData() {
    fetchPhysicians(`?Lat=${this.lat}&Lng=${this.lng}&ItemCount=5&PageNumber1`)
      .then((response) => (response && response.Results) || [])
      .then((ph) => {
        const physicians = ph.map((p) => {
          const dist = Math.round(p.DistanceFromRequest)

          return {
            address: `${p.Address1}, ${p.City}, ${p.State} ${p.Zip}`,
            phone: p.Phone ? `${p.Phone}` : null,
            name: `${p.First} ${p.Last}, ${p.ProfessionalDesignation}`,
            distance: `${dist} mile${dist > 1 ? "s" : ""} away`,
            lat: p.Lat,
            lng: p.Lng,
          }
        })

        this.setState((prevState) => ({
          ...prevState,
          physFetching: false,
          physicians,
        }))
      })
  }

  getPDFType() {
    let ess
    let sns
    let chad
    if (
      typeof window !== "undefined" &&
      typeof window.localStorage === "object"
    ) {
      ess = storage.getObject("ess")
      sns = storage.getObject("sns")
      chad = storage.getObject("chad")
    }
    if (ess && sns) {
      return "both"
    } else if (ess) {
      return "ess"
    } else if (sns) {
      return "sns"
    } else if (chad) {
      return "pediatric"
    }
  }

  generateGetParams() {
    const {
      EssScore,
      SnsScore,
      ChadScore,
      chad = {},
      ess = {},
      sns = {},
      lat = null,
      lng = null,
    } = this

    const { physicians } = this.state
    const latLng = { lat, lng }

    return pdfGetParams({
      essScore: EssScore,
      snsScore: SnsScore,
      chadScore: ChadScore,
      chad,
      ess,
      sns,
      physicians,
      latLng,
    })
  }

  calcLeftEss() {
    let multiplier = 0
    let left = 0
    let score = this.EssScore
    let test = window.innerWidth
    let max = 0

    if (test < 750) {
      multiplier = 9.35
      left = score * multiplier
      max = 220
      this.moveEss(left, "ess-marker-verticle", max)
    } else if (test >= 750 && test < 994) {
      multiplier = 16.5
      left = score * multiplier
      max = 390
      this.moveEss(left, "ess-marker-horizontal-md", max)
    } else if (test >= 994) {
      multiplier = 22
      left = score * multiplier
      max = 525
      this.moveEss(left, "ess-marker-horizontal-lg", max)
    }
  }

  calcLeftSns() {
    let multiplier = 0
    let left = 0
    let score = this.SnsScore + 50
    let test = window.innerWidth
    let max = 0

    if (test < 750) {
      multiplier = 4.3
      left = score * multiplier
      max = 270
      this.moveSns(left, "sns-marker-verticle", max)
    } else if (test >= 750 && test < 994) {
      multiplier = 5.68
      left = score * multiplier
      max = 390
      this.moveSns(left, "sns-marker-horizontal-md", max)
    } else if (test >= 994) {
      multiplier = 7.5
      left = score * multiplier
      max = 520
      this.moveSns(left, "sns-marker-horizontal-lg", max)
    }
  }

  moveSns(left, className, max) {
    var elem = document.getElementsByClassName(className)
    var pos = 0
    var id = setInterval(frame, 5)
    function frame() {
      if (elem[0]) {
        if (max === 270) {
          if (left <= max) {
            if (pos >= left) {
              clearInterval(id)
            } else {
              pos++
              elem[0].style.top = pos + "px"
            }
          } else {
            if (pos >= max) {
              clearInterval(id)
            } else {
              pos++
              elem[0].style.top = pos + "px"
            }
          }
        } else {
          if (pos >= left) {
            clearInterval(id)
          } else {
            pos++
            elem[0].style.left = pos + "px"
          }
        }
      }
    }
  }

  moveEss(left, className, max) {
    var elem = document.getElementsByClassName(className)
    var pos = 0
    var id = setInterval(frame, 5)
    function frame() {
      if (elem[0]) {
        if (max === 220) {
          if (left <= max) {
            if (pos >= left) {
              clearInterval(id)
            } else {
              pos++
              elem[0].style.top = pos + "px"
            }
          } else {
            if (pos >= max) {
              clearInterval(id)
            } else {
              pos++
              elem[0].style.top = pos + "px"
            }
          }
        } else {
          if (pos >= left) {
            clearInterval(id)
          } else {
            pos++
            elem[0].style.left = pos + "px"
          }
        }
      }
    }
  }

  getSymptomResultsRequest() {
    return {
      essScores: this.EssScores,
      snsScores: this.SnsScores,
      essScore: this.EssScore,
      snsScore: this.SnsScore,
      zip: this.zipCode,
      email: document.getElementById("your-email").value,
    }
  }

  printResults(event) {
    event.preventDefault()
    let screenerPdfWindow = window.open(
      `${
        process.env.REACT_APP_API_HOST
      }/symptom-screener.php?data=${encodeURIComponent(
        JSON.stringify(this.getSymptomResultsRequest())
      )}`
    )
    screenerPdfWindow.focus()
  }

  componentDidMount() {
    this.calcLeftEss()
    this.calcLeftSns()
    this.fetchPhysicianData()
  }

  renderScoreResults() {
    const { physicians } = this.state

    const chadCard = (
      <div className="appointment-results-card">
        <img className="calendar-icon" src={calendarIcon} alt="" />
        <h2 className="appointment-results-header">SEE A SLEEP SPECIALIST</h2>
        <div className="step-screener step-screener--score pl-5">
          <div className="row">
            <div className="col-12 col-md-7 col-lg-9 text--body-copy-dark">
              <p className="text__title--extralight mb-0">
                Schedule an appointment with a sleep specialist to discuss your
                child's ESS-CHAD score.
              </p>
            </div>
            <div className="col-12 col-md-5 col-lg-3 pr-50 pr-sm-0">
              {physicians.length ? (
                <TrackingWrapper gtmTrackId="FASSPrintCHAD">
                  <a
                    target="_blank"
                    href={`/php/api/download_result.php?type=pediatric&${this.generateGetParams()}`}
                    className="print-directions physicians text--brand-primary d-block pr-15"
                  >
                    <img src={printIcon} alt="" /> Print Screener Results
                  </a>
                </TrackingWrapper>
              ) : (
                <a
                  target="_blank"
                  href="#"
                  onClick={(e) => e.preventDefault()}
                  className="print-directions physicians text--brand-primary d-block pr-15 disabled"
                >
                  <img src={printIcon} alt="" /> Print Screener Results
                </a>
              )}
            </div>
          </div>
          <div className="row mt-10 pl-50 pr-50 pl-sm-0 pr-sm-0">
            <div className="col-12 col-md-4 mt-20">
              <div className="text-left">
                <h2 className="score-text text__weight--semibold text__title--3">
                  Your child's ESS-CHAD score is:
                </h2>
                <div className="score-number score-number-chad">
                  {this.ChadScore}
                </div>
              </div>
              <p className="score-explanation-chad mobile text__size--small text__weight--light hidden-md-up mb-0">
                An ESS-CHAD score greater than 10 suggests excessive daytime
                sleepiness. An ESS-CHAD score of 16 or higher suggests a high
                level of excessive daytime sleepiness. You should discuss your
                child’s ESS-CHAD score with your sleep specialist.
              </p>
            </div>
            <div className="col-12 col-md-8 mt-20">
              <div className="text-left">
                <p className="score-text text__weight--semibold text__title--3">
                  Interpreting ESS-CHAD Scores
                </p>
                <ChadScale chadScore={this.ChadScore} />
              </div>
            </div>
            <div className="col-12 mt-20 hidden-sm-down">
              <p className="score-explanation-chad text__size--small text__weight--light">
                An ESS-CHAD score greater than 10 suggests excessive daytime
                sleepiness. An ESS-CHAD score of 16 or higher suggests a high
                level of excessive daytime sleepiness. You should discuss your
                child’s ESS-CHAD score with your sleep specialist.
              </p>
            </div>
          </div>
        </div>
      </div>
    )

    const essSection = (
      <div className="row mt-10 pl-50 pr-50 pl-sm-0 pr-sm-0">
        <div className="col-12 col-md-4 mt-20">
          <div className="text-left">
            <h2 className="score-text text__weight--semibold text__title--3">
              Your ESS score is:
            </h2>
            <div className="score-number score-number-ess">{this.EssScore}</div>
          </div>
          <p className="score-explanation-ess mobile text__size--small text__weight--light hidden-md-up mb-0">
            An ESS score greater than 10 suggests excessive daytime sleepiness.
            An ESS score of 16 or higher suggests a high level of excessive
            daytime sleepiness. You should discuss your ESS score with your
            sleep specialist.
          </p>
        </div>
        <div className="col-12 col-md-8 mt-20">
          <div className="text-left">
            <p className="score-text text__weight--semibold text__title--3">
              Interpreting ESS Scores
            </p>
            <EssScale essScore={this.EssScore} />
          </div>
        </div>
        <div className="col-12 mt-20 hidden-sm-down">
          <p className="score-explanation-ess text__size--small text__weight--light">
            An ESS score greater than 10 suggests excessive daytime sleepiness.
            An ESS score of 16 or higher suggests a high level of excessive
            daytime sleepiness. You should discuss your ESS score with your
            sleep specialist.
          </p>
        </div>
      </div>
    )

    const snsSection = (
      <div className="row mt-10 pl-50 pr-50 pl-sm-0 pr-sm-0">
        <div className="col-12 col-md-4 mt-20">
          <div className="text-left">
            <h2 className="score-text text__weight--semibold text__title--3">
              Your SNS score is:
            </h2>
            <div className="score-number score-number-sns">{this.SnsScore}</div>
          </div>
          <p className="score-explanation-sns mobile text__size--small text__weight--light hidden-md-up mb-0">
            An SNS calculated score that is less than 0 is suggestive of
            narcolepsy with cataplexy. You should discuss your SNS score with
            your sleep specialist.
          </p>
        </div>
        <div className="col-12 col-md-8 mt-20">
          <div className="text-left">
            <p className="score-text text__weight--semibold text__title--3">
              Interpreting SNS Scores
            </p>
            <SnsScale snsScore={this.SnsScore} />
          </div>
        </div>
        <div className="col-12 mt-20 hidden-sm-down">
          <p className="score-explanation-sns text__size--small text__weight--light">
            An SNS calculated score that is less than 0 is suggestive of
            narcolepsy with cataplexy. You should discuss your SNS score with
            your sleep specialist.
          </p>
        </div>
      </div>
    )

    return (
      <Fragment>
        {this.chadTestAvl && chadCard}

        {(() => {
          if (this.essTestAvl || this.snsTestAvl) {
            return (
              <div className="appointment-results-card">
                <img className="calendar-icon" src={calendarIcon} alt="" />
                <h2 className="appointment-results-header">
                  SEE A SLEEP SPECIALIST
                </h2>
                <div className="step-screener step-screener--score pl-5">
                  <div className="row">
                    <div className="col-12 col-md-7 col-lg-8 text--body-copy-dark">
                      <p>
                        Schedule an appointment with a sleep specialist to
                        discuss your ESS and/or SNS score.
                      </p>
                    </div>
                    {console.log(physicians.length)}
                    <div className="col-12 col-md-5 col-lg-4 pr-50 pr-sm-0">
                      {physicians.length ? (
                        <TrackingWrapper gtmTrackId="FASSPrintAdult">
                          <a
                            target="_blank"
                            href={`/php/api/download_result.php?type=${this.getPDFType()}&${this.generateGetParams()}`}
                            className="print-directions physicians text--brand-primary d-block pr-15"
                          >
                            <img src={printIcon} alt="" /> Print Screener
                            Results
                          </a>
                        </TrackingWrapper>
                      ) : (
                        <a
                          target="_blank"
                          href="#"
                          onClick={(e) => e.preventDefault()}
                          className="print-directions physicians text--brand-primary d-block pr-15 disabled"
                        >
                          <img src={printIcon} alt="" /> Print Screener Results
                        </a>
                      )}
                    </div>
                  </div>
                  {this.essTestAvl && essSection}
                  {this.essTestAvl && this.snsTestAvl ? (
                    <hr className="both-score-hr" />
                  ) : (
                    <Fragment />
                  )}
                  {this.snsTestAvl && snsSection}
                </div>
              </div>
            )
          } else {
            return <Fragment />
          }
        })()}
      </Fragment>
    )
  }

  renderNoResults() {
    return (
      <div className="appointment-results-card">
        <img className="calendar-icon" src={calendarIcon} alt="" />
        <h2 className="appointment-results-header">See A Sleep Specialist</h2>
        <p className="appointment-results-explanation">
          Schedule an appointment with a sleep specialist. Share the results
          from the{" "}
          <TrackingWrapper gtmTrackId="FASSScreener">
            <Link
              className="text__link--underline"
              to={getPath("CouldItBeNarcolepsy")}
            >
              screeners
            </Link>
          </TrackingWrapper>{" "}
          and use the{" "}
          <TrackingWrapper gtmTrackId="FASSConvoStarter">
            <a
              className="text__link--underline"
              href="/pdf/narcolepsy-conversation-starter.pdf"
              target="_blank"
            >
              Narcolepsy Conversation Starter
            </a>
          </TrackingWrapper>{" "}
          at the first visit.{" "}
        </p>
      </div>
    )
  }

  render() {
    let renderContent = null
    if (this.chadTestAvl || this.essTestAvl || this.snsTestAvl) {
      renderContent = this.renderScoreResults.bind(this)
    } else {
      renderContent = this.renderNoResults.bind(this)
    }
    return renderContent()
  }
}

export default AppointmentResults
