import React, { useState, useEffect, useRef } from 'react';
import {
	ScreenerNavigation,
	YourNextSteps,
	DownloadOverlay,
	EmailOverlay,
} from '@component-structure/AdultScreenerElements';
import {
	TrackingWrapper,
	customEventTypes,
	gtmDataLayerPush,
	gtmTrackingContexts,
} from '@component-utility/TrackingTools';
import ScreenerESS from '@component-utility/ScreenerESS';
import ScreenerSNS from '@component-utility/ScreenerSNS';
import { ModalExternalLink } from '@component-structure/ModalExternalLink';
import AppStore from '@images/apple_app_store.png';
import GooglePlay from '@images/google_play.png';

const AdultSymptomScreener = () => {
	const [unscrolled, setUnscrolled] = useState(true);
	const [sticky, setSticky] = useState(false);
	const [sectionSNS, setSectionSNS] = useState(false);

	const refESS = useRef(null);
	const refSNS = useRef(null);

	useEffect(() => {
		window.addEventListener && window.addEventListener('scroll', onScroll);

		document.getElementsByClassName('footer__top-text')[0].innerHTML =
			'These screening tools are not intended to make a narcolepsy diagnosis or replace complete evaluation by a sleep specialist.';

		return () => {
			window.removeEventListener('scroll', onScroll);

			document.getElementsByClassName('footer__top-text')[0].innerHTML =
				'The content on this site is not meant to replace a conversation with a sleep specialist. A sleep specialist can evaluate your symptoms and make a diagnosis.';
		};
	});

	const onScroll = () => {
		unscrolled && setUnscrolled(false);
		const fromTop = document.documentElement.scrollTop;
		// Check if screener nav should stick
		let scrollDepthNeeded;
		if (window.innerWidth >= 992) {
			scrollDepthNeeded = 52;
		} else {
			scrollDepthNeeded = 62;
		}

		if (fromTop > scrollDepthNeeded && !sticky) {
			setSticky(true);
		} else if (fromTop <= scrollDepthNeeded && sticky) {
			setSticky(false);
		}
		// Check which screener they're scrolled to
		if (fromTop >= refSNS.current.offsetTop - 120 && !unscrolled) {
			setSectionSNS(true);
		} else if (fromTop < refSNS.current.offsetTop - 120 && !unscrolled) {
			setSectionSNS(false);
		}
	};

	const [screenerType, setScreenerType] = useState(null);
	const [calculatedESS, setCalculatedESS] = useState(false);
	const [calculatedSNS, setCalculatedSNS] = useState(false);

	useEffect(() => {
		gtmDataLayerPush({
			event: customEventTypes.bothScreenersSubmitCheck,
			gtmTrackingContext: gtmTrackingContexts.screenerAdultESS,
			value: {
				bothCalculated: calculatedESS && calculatedSNS ? true : false,
			},
		});
	}, [calculatedESS, calculatedSNS]);

	const [isOpenDownload, setIsOpenDownload] = useState(false);

	const toggleDownloadModal = (e) => {
		e.preventDefault();
		setIsOpenDownload(!setIsOpenDownload);
	};

	const [isOpenEmail, setIsOpenEmail] = useState(false);

	const toggleEmailModal = (e) => {
		e.preventDefault();
		setIsOpenEmail(!setIsOpenEmail);
	};

	return (
		<div className='adult-screener-page'>
			<ScreenerNavigation
				sticky={sticky}
				unscrolled={unscrolled}
				sectionSNS={sectionSNS}
				refESS={refESS}
				refSNS={refSNS}
			/>
			<div
				className={`sticky-nav-gray ${sticky ? 'sticky-nav-gray-active' : ''}`}
			/>
			<div className='screeners-wrapper'>
				<ScreenerESS
					refESS={refESS}
					setIsOpenDownload={setIsOpenDownload}
					setIsOpenEmail={setIsOpenEmail}
					setScreenerType={setScreenerType}
					calculatedESS={calculatedESS}
					calculatedSNS={calculatedSNS}
					setCalculatedESS={setCalculatedESS}
				/>
				<hr className='screener-divider' />
				<div className='screener-gradient' />
				<ScreenerSNS
					refSNS={refSNS}
					setIsOpenDownload={setIsOpenDownload}
					setIsOpenEmail={setIsOpenEmail}
					setScreenerType={setScreenerType}
					calculatedESS={calculatedESS}
					calculatedSNS={calculatedSNS}
					setCalculatedSNS={setCalculatedSNS}
				/>
				<YourNextSteps />
			</div>
			<DownloadOverlay
				isOpen={isOpenDownload}
				toggle={toggleDownloadModal}
				adultDownload={true}
				screenerType={screenerType}
			/>
			<EmailOverlay
				isOpen={isOpenEmail}
				toggle={toggleEmailModal}
				adultEmail={true}
				screenerType={screenerType}
			/>
		</div>
	);
};

export default AdultSymptomScreener;
