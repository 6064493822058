import React, { Component } from 'react';
import { Link } from 'gatsby';
import { getPath } from '@component-utility/pagelist';
import { fetchPhysicians } from '@component-utility/requests';
import { win, gmapsLoaded, isEDMode } from '@component-utility/utils';

import MapContainer from '@component-utility/mapContainer';
import EmailFinder from '@component-utility/EmailFinder';
import PhysicianCards from '@component-structure/PhysicianCards';
import PhysicianDetail from '@component-structure/PhysicianDetail';
import AppointmentResults from '@component-structure/AppointmentResults';
import MapPlaceholder from '@images/findASleepSpecialist/map-placeholder.png';
import StepDirections from '@component-utility/StepDirections';
import EmailPhysicianDetails from '@component-utility/EmailPhysicianDetails';
import {
	gtmDataLayerPush,
	TrackingWrapper,
	customEventTypes,
	gtmTrackingContexts,
} from '@component-utility/TrackingTools';

export class ResultSearchComponent extends Component {
	constructor() {
		super();
		this.state = {
			physicianData: [],
			placeName: win ? localStorage.getItem('placeName') : '',
			loading: false,
			mapAreaChange: false,
			removeMarkers: true,
			clearMapDirections: true,
			viewMapMobile: false,
			desktopWidthAcheived: false,
			newBounds: null,
			showModal: false,
			firstSearch: true,
		};

		this.queryParams = {
			lat: win ? localStorage.getItem('lat') : '',
			lng: win ? localStorage.getItem('lng') : '',
			GoogleAnalyticsCampaignCode: '',
			serviceKey: '',
			ItemCount: 10,
			pageNumber: 1,
			pediatricSpecialistsOnly: false,
		};

		this.getPhysicians = this.getPhysicians.bind(this);
		this.changePlaceName = this.changePlaceName.bind(this);
		this.extractCityAddr = this.extractCityAddr.bind(this);
		this.redoSearch = this.redoSearch.bind(this);
		this.showPhysicianDetails = this.showPhysicianDetails.bind(this);
		this.closePhysicianDetails = this.closePhysicianDetails.bind(this);
		this.showMapMobile = this.showMapMobile.bind(this);
		this.passMapDirectionsData = this.passMapDirectionsData.bind(this);
		this.pediatricSpecialistsOnlyChanged =
			this.pediatricSpecialistsOnlyChanged.bind(this);
		this.mapBoundsMoved = this.mapBoundsMoved.bind(this);
		this.clickedModal = this.clickedModal.bind(this);
		this.physicianResultFindings = this.physicianResultFindings.bind(this);
	}

	componentDidMount() {

		if(isEDMode) {
			this.state.placeName = '90210'
			this.queryParams.lat = "34.1030032"
			this.queryParams.lng = "-118.4104684"
		}
		this.getPhysicians();
		this.determineMapZoomMobleDesktop();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.resizeOnWindowResize);
	}

	render() {
		const {
			showDetails,
			physicianData,
			placeName,
			bounds,
			clearMapDirections,
			mapDirections,
			removeMarkers,
			loading,
			viewMapMobile,
			startingLocation,
			newBounds,
			showModal,
			firstSearch,
			redoPhysicianData,
		} = this.state;

		return (
			<div className='physician-results'>
				<div className='primary-container'>
					{showDetails ? null : (
						<div className='physician-results-header'>
							<h1 className='text__title text__title--1 text--body-copy-dark mt-50 mt-md-30'>
								Select a Sleep Specialist
							</h1>
							<div className='select-sub-info'>
								<p className='results-location'>
									Results near <span> {placeName} </span>
									<TrackingWrapper gtmTrackId='FASSChangeLoc'>
										<Link to={getPath('FindASleepSpecialist')}>
											{' '}
											change location{' '}
										</Link>
									</TrackingWrapper>
								</p>
								<div className='ped-filter'>
									<input
										type='checkbox'
										id='ped-patients-checkbox'
										className='ped-checkbox'
										checked={
											this.queryParams.pediatricSpecialistsOnly
										}
										onChange={this.pediatricSpecialistsOnlyChanged}
									/>
									<label htmlFor='ped-patients-checkbox' />
									<label>
										Show only providers who treat pediatric patients
									</label>
								</div>
							</div>
						</div>
					)}
				</div>

				<div className='results-wrapper'>
					<div className='desktopleft-mobilebottom'>
						{showDetails ? (
							<div className='results-list co-details-desktop'>
								<PhysicianDetail
									doctor={physicianData[0]}
									closePhysicianDetails={this.closePhysicianDetails}
								/>
							</div>
						) : null}
						{showDetails ? (
							<EmailPhysicianDetails doctor={physicianData[0]} />
						) : null}
						{showDetails ? null : (
							<div className='co-list'>
								<PhysicianCards
									doctors={physicianData}
									getPhysicians={this.getPhysicians}
									loading={loading}
									showPhysicianDetails={this.showPhysicianDetails}
									redoBounds={bounds}
									firstSearch={this.state.firstSearch}
								/>
							</div>
						)}
						{showDetails ? (
							<StepDirections
								doctor={physicianData[0]}
								mapDirections={this.passMapDirectionsData}
							/>
						) : null}
					</div>

					<div className='map-wrapper desktopright-mobiletop'>
						{showDetails ? (
							<div className='results-list co-details-mobile'>
								<PhysicianDetail
									doctor={physicianData[0]}
									closePhysicianDetails={this.closePhysicianDetails}
								/>
							</div>
						) : null}
						{viewMapMobile ? null : (
							<div
								className='mobile-map-placeholder'
								onClick={this.showMapMobile}>
								<h5>
									View Map
									<i className='fa fa-expand' />
								</h5>
								<img src={MapPlaceholder} alt='gmaps placeholder' />
							</div>
						)}
						<div className={viewMapMobile ? 'post-open-map' : 'pre-open-map'}>
							<MapContainer
								mapData={physicianData}
								bounds={bounds}
								displayPhysicianDetails={showDetails}
								removeMarkersNeeded={removeMarkers}
								mapDirections={mapDirections}
								clearMapDirections={clearMapDirections}
								startingLocation={startingLocation}
								mapBoundsMoved={this.mapBoundsMoved}
								showModal={showModal}
								firstSearch={firstSearch}
								redoPhysdata={redoPhysicianData}
							/>
						</div>
					</div>
					{showModal && !showDetails ? (
						<div className='redo-search-wrapper'>
							<h4>Find Additional Specialists:</h4>
							<p>
								Click below to see Sleep Specialists on this section of
								the map.
							</p>
							<button
								className='redo-search-button'
								onClick={() => this.clickedModal(newBounds)}>
								Redo Search
							</button>
						</div>
					) : null}
				</div>
				{showDetails ? (
					<AppointmentResults />
				) : (
					<EmailFinder key='email-finder-results' gtmTrackContext='Results' />
				)}
			</div>
		);
	}

	findBounds(results) {
		return {
			north: Math.max.apply(
				Math,
				results.map(function (physician) {
					return physician.Lat;
				})
			),
			south: Math.min.apply(
				Math,
				results.map(function (physician) {
					return physician.Lat;
				})
			),
			east: Math.max.apply(
				Math,
				results.map(function (physician) {
					return physician.Lng;
				})
			),
			west: Math.min.apply(
				Math,
				results.map(function (physician) {
					return physician.Lng;
				})
			),
		};
	}

	pediatricSpecialistsOnlyChanged() {
		const eventTag = customEventTypes.formSubmit,
			context = gtmTrackingContexts.findASleepSpecialistToggle;
		this.queryParams.pediatricSpecialistsOnly =
			!this.queryParams.pediatricSpecialistsOnly;
		this.queryParams.pageNumber = 1;
		const evtPayload = {
			event: eventTag,
			gtmTrackingContext: context,
			value: {
				isPediatric: this.queryParams.pediatricSpecialistsOnly,
			},
		};

		this.setState(
			{
				physicianData: [],
				redoSearchNeeded: false,
				removeMarkers: true,
			},
			() => {
				gtmDataLayerPush(evtPayload);
				this.getPhysicians();
			}
		);
	}

	async getPhysicians(redoBounds) {
		if (!this.state.loading && this.state.physicianData.length < 30) {
			let queryString = `?Lat=${this.queryParams.lat}&Lng=${this.queryParams.lng}&ItemCount=${this.queryParams.ItemCount}&PageNumber=${this.queryParams.pageNumber}&PediatricSpecialistsOnly=${this.queryParams.pediatricSpecialistsOnly}`;

			this.setState({
				loading: true,
			});

			try {
				const data = await fetchPhysicians(queryString);

				console.log('data', data);
				console.log('queryString', queryString);

				if (data) {
					const physiciansInNewBounds = [];
					if (redoBounds) {
						for (let physician of data.Results) {
							if (
								physician.Lat < redoBounds.north &&
								physician.Lat > redoBounds.south &&
								physician.Lng > redoBounds.west &&
								physician.Lng < redoBounds.east
							) {
								physiciansInNewBounds.push(physician);
							}
						}
						this.setState({
							redoPhysicianData: physiciansInNewBounds,
						});
					}
					this.setState(
						(prevState) => ({
							physicianData: redoBounds
								? this.state.redoPhysicianData
								: [...prevState.physicianData, ...data.Results],
							// bounds: physiciansInNewBounds.length > 0 ? this.findBounds(physiciansInNewBounds) : this.findBounds(data.Results),
							bounds: redoBounds
								? this.physicianResultFindings(physiciansInNewBounds)
								: this.findBounds(data.Results),
							loading: false,
							removeMarkers: true,
						}),
						() => {
							this.assignPhysiciansNumbers();
							//this calculation is necessary to ensure when returning from a details view, when more than 10 results are loades initially, on the next scroll the page number is correct
							this.queryParams.pageNumber =
								this.state.physicianData.length / 10 + 1;
							this.queryParams.ItemCount = 10;
						}
					);
				}
			} catch (err) {
				this.setState({
					isLoading: false,
				});
				throw err;
			}
		}
	}

	physicianResultFindings(physiciansInNewBounds) {
		if (physiciansInNewBounds.length > 0) {
			return this.findBounds(physiciansInNewBounds);
		} else {
			// this is the area we will have 0 results for redo search go
			// this.setState({
			//   somethingSomething: 'noResults'
			// })
		}
	}

	changePlaceName(location) {
		if (win && gmapsLoaded()) {
			new window.google.maps.Geocoder().geocode(
				{
					location: {
						lat: (location.north + location.south) / 2,
						lng: (location.east + location.west) / 2,
					},
				},
				this.extractCityAddr
			);
		}
	}

	extractCityAddr(results, status) {
		if (status === 'OK' && results[0]) {
			const { address_components } = results[0];
			const addressEl = address_components
				? address_components.filter((addrChunk) =>
						addrChunk.types.includes('locality')
				  )
				: [];
			if (addressEl.length > 0) {
				const newName = addressEl[0].short_name;
				localStorage.setItem('placeName', newName);
				this.setState({ placeName: newName });
			}
		}
	}

	mapBoundsMoved(newBounds) {
		this.setState({
			newBounds: newBounds,
			showModal: true,
			firstSearch: false,
		});
	}

	clickedModal(newBounds) {
		this.setState(
			{
				newBounds: null,
				showModal: false,
			},
			() => {
				this.redoSearch(newBounds);
			}
		);
	}

	redoSearch(redoBounds) {
		const prevQueryParams = this.queryParams;
		var lat = redoBounds.center.lat().toString();
		var lng = redoBounds.center.lng().toString();

		this.changePlaceName(redoBounds);

		localStorage.setItem('lat', lat);
		localStorage.setItem('lng', lng);

		this.queryParams = Object.assign(prevQueryParams, {
			lng: lng,
			lat: lat,
			GoogleAnalyticsCampaignCode: '',
			serviceKey: '',
			ItemCount: 30,
			pageNumber: 1,
		});

		this.setState(
			{
				bounds: redoBounds,
				physicianData: [],
				removeMarkers: true,
				viewMapMobile: true,
			},
			() => {
				this.getPhysicians(redoBounds);
			}
		);
	}

	showMapMobile() {
		this.setState(
			{
				redoSearchNeeded: false,
				removeMarkers: true,
				viewMapMobile: true,
			},
			() => {
				this.setState({
					redoSearchNeeded: false,
				});
			}
		);
	}

	showPhysicianDetails(doctor) {
		this.setState({
			physicianData: [doctor],
			redoSearchNeeded: false,
			showDetails: true,
			removeMarkers: true,
		});
	}

	closePhysicianDetails() {
		//checks what page number results were on to determine how many results to get on first get
		this.queryParams.ItemCount = (this.queryParams.pageNumber - 1) * 10;
		this.queryParams.pageNumber = 1;
		this.setState({
			physicianData: [],
			redoSearchNeeded: false,
			showDetails: false,
			removeMarkers: true,
			clearMapDirections: true,
		});
		this.getPhysicians();
	}

	passMapDirectionsData(mapDirections, startingLocation) {
		this.setState({
			startingLocation: startingLocation,
			mapDirections: mapDirections,
			clearMapDirections: false,
			removeMarkers: true,
		});
	}

	assignPhysiciansNumbers() {
		let physicians = this.state.physicianData;
		for (let i = 0; i < physicians.length; i++) {
			physicians[i].markerNumber = (i + 1).toString();
		}

		this.setState({
			physicianData: physicians,
		});
	}

	determineMapZoomMobleDesktop() {
		const startingWidth = window.innerWidth;
		if (startingWidth > 750) {
			this.setState({
				desktopWidthAchieved: true,
			});
		} else if (
			this.state.desktopWidthAchieved === false &&
			!this.state.viewMapMobile
		) {
			this.resizeOnWindowResize = this.resizeOnWindowResize.bind(this);
			window.addEventListener('resize', this.resizeOnWindowResize);
		}
	}

	resizeOnWindowResize(event) {
		if (event.target.innerWidth > 750) {
			this.setState({
				desktopWidthAchieved: true,
			});
		}
	}
}

export default ResultSearchComponent;
