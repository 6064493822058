import React, { Component } from "react"
import PropTypes from "prop-types"
import { win, gmapsLoaded } from "@component-utility/utils"
import { autocompleteOptsByCities } from "@config/map-styles"
import { getPath } from "@component-utility/pagelist"
import { navigate } from "gatsby" 
import {
  gtmDataLayerPush,
  customEventTypes,
  gtmTrackingContexts
} from "@component-utility/TrackingTools"

export class SearchBoxPhysicians extends Component {
  initSearchMap() {
    if (win && gmapsLoaded()) {
      console.log('gmap loaded');
      let input = this.zipcodeInputRef.current
      let autocomplete = new window.google.maps.places.Autocomplete(
        input,
        autocompleteOptsByCities
      )

      new window.google.maps.event.addListener(
        autocomplete,
        "place_changed",
        () => {
          var place = autocomplete.getPlace()

          input.focus()
          var bounds = new window.google.maps.LatLngBounds()

          if (!place.geometry) {
            //if the user submits a bogus location after a legitimate one, disable the status.
            this.setState({
              blockSearch: true,
              place: ""
            })
            return
          }

          localStorage.setItem("placeName", place.name)
          this.setState({
            blockSearch: false,
            place: place.name
          })

          bounds.extend(place.geometry.location)
          localStorage.setItem("bounds", JSON.stringify(bounds))
          localStorage.setItem("lat", bounds.getCenter().lat())
          localStorage.setItem("lng", bounds.getCenter().lng())
        }
      )
    }
  }

  submitSearch(e) {
    const { blockSearch, place } = this.state,
      eventType = customEventTypes.formSubmit,
      gtmContext = gtmTrackingContexts.findASleepSpecialistSearch

    e.preventDefault()

    if (!blockSearch) {
      const physicianSearch = getPath("ResultSearchComponent")
      gtmDataLayerPush({
        event: eventType,
        gtmTrackingContext: gtmContext,
        value: {
          zipcodeSearch: place
        }
      })
      navigate(physicianSearch);
    }
  }

  constructor(props) {
    super(props)

    this.state = {
      blockSearch: true,
      place: ""
    }

    this.zipcodeInputRef = React.createRef()

    this.initSearchMap = this.initSearchMap.bind(this)
    this.submitSearch = this.submitSearch.bind(this)
  }

  componentDidMount() {
    if (win && gmapsLoaded()) {
      this.initSearchMap()
    } else {
      window.initMap = this.initSearchMap
    }
  }

  render() {
    const { blockSearch } = this.state
    return (
      <div className="search-component-wrapper">
        <div className="search-input" id="pac-container">
          <form id="physicianSearch" onSubmit={this.submitSearch}>
            <input
              id="pac-input"
              ref={this.zipcodeInputRef}
              type="text"
              placeholder="ZIP CODE OR CITY, STATE"
              style={{ height: "60px" }}
            />
          </form>
        </div>
        <div className="search-button-wrapper">
          <button
            className="pp-search-button"
            disabled={blockSearch}
            onClick={this.submitSearch}
          >
            Search<span className="search-arrow">
              <i className="fa fa-chevron-right" />
            </span>
          </button>
        </div>
      </div>
    )
  }
}

export default SearchBoxPhysicians

SearchBoxPhysicians.propTypes = {
  runMapSearch: PropTypes.func
}
