import React, { Fragment } from "react"
import { win } from "@component-utility/utils"

export class TrackingWrapper extends React.Component {
  addGtmTracker(element, trackId) {
    return React.cloneElement(element, {
      gtmtrackid: trackId,
      key: element.type,
    })
  }
  render() {
    const { children, gtmTrackId } = this.props

    let clone = children

    if (typeof children.map === "function") {
      clone = children.map((child) => {
        if (child) {
          return this.addGtmTracker(child, gtmTrackId)
        } else {
          return ""
        }
      })
    } else {
      clone = this.addGtmTracker(children, gtmTrackId)
    }

    return <Fragment>{clone}</Fragment>
  }
}

export class TrackingPhoneWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.trackPhoneTap = this.trackPhoneTap.bind(this)
  }

  trackPhoneTap() {
    const { gtmTrackId } = this.props
    gtmDataLayerPush({
      event: customEventTypes.phoneTap,
      gtmTrackingContext: gtmTrackId,
    })
  }

  render() {
    const { children } = this.props
    return <span onTouchStart={this.trackPhoneTap}>{children}</span>
  }
}

export const gtmDataLayerPush = (payload) => {
  if (win && win.dataLayer) {
    if (typeof payload === "object") {
      win.dataLayer.push(payload)
    } else {
      new Error("you can only push an object to the GTM datalayer!")
    }
  }
}

export const trackScreenerAnswers = (answerObject, type) => {
  let eventType
  let trackingContext
  if (type === "ess") {
    eventType = customEventTypes.ScreenerQESS
    trackingContext = gtmTrackingContexts.screenerAdultESS
  } else if (type === "sns") {
    eventType = customEventTypes.ScreenerQSNS
    trackingContext = gtmTrackingContexts.screenerAdultSNS
  } else if (type === "chad") {
    eventType = customEventTypes.ScreenerQCHAD
    trackingContext = gtmTrackingContexts.screenerChild
  }
  Object.keys(answerObject).forEach((answer) => {
    gtmDataLayerPush({
      event: eventType,
      gtmTrackingContext: trackingContext,
      value: {
        question: answer,
        answer: answerObject[answer],
      },
    })
  })
}

export const customEventTypes = {
  video25: "VIDEO25",
  videoPlay: "VIDEOPLAY",
  playlistPlay: "PLAYLIST_VIDEOPLAY",
  ScreenerQ: "SCREENER_QUESTION",
  ScreenerQESS: "SCREENER_QUESTION_ESS",
  ScreenerQSNS: "SCREENER_QUESTION_SNS",
  ScreenerQCHAD: "SCREENER_QUESTION_CHAD",
  formSubmit: "FORM_SUBMIT",
  bothScreenersSubmitCheck: "BOTH_SCREENERS_CHECK",
  phoneTap: "PHONE_TAP",
  openView: "OPEN_VIEW",
  leavingSiteModalOnLoad: "LEAVING_SITE_ONLOAD",
  leavingSiteModalOK: "LEAVING_SITE_OK",
  leavingSiteModalCancel: "LEAVING_SITE_CANCEL",
  overallHealthModal: "OPEN_OVERALL_HEALTH_MODAL",
}

export const gtmTrackingContexts = {
  findASleepSpecialistSearch: "FASSSearch",
  findASleepSpecialistToggle: "FASSSearchToggle",
  findASleepSpecialistDirections: "FASSDirections",
  findASleepSpecialistShare: "FASSShare",
  findASleepSpecialistShareDetail: "FASSShareDetail",
  screenerAdultESS: "adultScreenerESS",
  screenerAdultSNS: "adultScreenerSNS",
  screenerChild: "CHADScreener",
  screenerOptionsESS: "adultScreenerOptsESS",
  screenerOptionsSNS: "adultScreenerOptsSNS",
  screenerChildOptions: "CHADScreenerOpts",
  screenerAdultSend: "adultScreenerSend",
  screenerChildSend: "CHADScreenerSend",
  home: "home",
  whatIsNarcolepsy: "whatIsNarco",
  symptoms: "symptoms",
  connect: "connectJoin",
  connectFullForm: "connectFullForm",
  shareSite: "shareSite",
  orderMaterial: "orderMaterial",
  overallHealthWatchNow: "watch now - overall health",
}
