import React from "react"
import { getPath } from "@component-utility/pagelist"
import { Link } from "gatsby"
import config from "@config/config"
import { ModalExternalLink } from "@component-structure/ModalExternalLink"
import { TrackingWrapper } from "@component-utility/TrackingTools"

const { narcolepsyLink, joinNlPath } = config

export const ErrorPageBody = ({ gtmTrackingContext }) => (
  <div className="row">
    <div className="col-12 col-lg-6 col__indent--left mt-20">
      <div className="row">
        <div className="col-12 col-sm-7 col-lg-6">
          <dl className="text__weight--base line-height--24">
          <dd className="mb-15">
              <Link
                to={getPath("WhatIsNarcolepsy")}
                className="text--brand-primary text__title"
              >
                What Is Narcolepsy?
              </Link>
            </dd>
            <dd className="mb-10">
              <Link
                to={getPath("SymptomsOfNarcolepsy")}
                className="text--brand-primary"
              >
                Symptoms of Narcolepsy
              </Link>
            </dd>
            <dd className="mb-10">
              <Link
                to={getPath("PotentialImpact")}
                className="text--brand-primary"
              >
                Potential Impact
              </Link>
            </dd>
            <dd className="mb-10">
              <Link
                to={getPath("WhatCausesNarcolepsy")}
                className="text--brand-primary"
              >
                What Causes Narcolepsy?
              </Link>
            </dd>
            <dd className="mb-10">
              <Link
                to={getPath("PediatricNarcolepsy")}
                className="text--brand-primary"
              >
                Narcolepsy in Children and Adolescents
              </Link>
            </dd>
            <dd className="mb-10">
              <Link
                to={getPath("CaringForYourChild")}
                className="text--brand-primary"
              >
                Children With Narcolepsy May Need{" "}
                <span className="d-inline-block">Extra Care</span>
              </Link>
            </dd>
          </dl>

          <dl className="text__weight--base line-height--24 mt-30">
          <dd className="mb-15">
              <Link
                to={getPath("CouldItBeNarcolepsy")}
                className="text--brand-primary text__title"
              >
                Could It Be Narcolepsy?
              </Link>
            </dd>
            <dd className="mb-10">
              <Link
                to={getPath("AdultSymptomScreener")}
                className="text--brand-primary"
              >
                Screeners for Adults
              </Link>
            </dd>
            <dd className="mb-10">
              <Link
                to={getPath("PediatricSymptomScreener")}
                className="text--brand-primary"
              >
                Screener for Children and Adolescents
              </Link>
            </dd>
          </dl>

          <dl className="text__weight--base line-height--24 mt-30">
          <dd className="mb-15">
              <Link
                to={getPath("GettingADiagnosis")}
                className="text--brand-primary text__title"
              >
                Getting a Diagnosis
              </Link>
            </dd>
            <dd className="mb-10">
              <Link
                to={getPath("MisunderstoodMisdiagnosed")}
                className="text--brand-primary"
              >
                Misunderstood/Misdiagnosed
              </Link>
            </dd>
            <dd className="mb-10">
              <Link
                to={getPath("SleepSpecialists")}
                className="text--brand-primary"
              >
                Sleep Specialists
              </Link>
            </dd>
            <dd className="mb-10">
              <Link
                to={getPath("DiagnosticTests")}
                className="text--brand-primary"
              >
                Diagnostic Tests
              </Link>
            </dd>
            <dd className="mb-10">
              <Link
                to={getPath("OngoingMonitoring")}
                className="text--brand-primary"
              >
                Ongoing Monitoring
              </Link>
            </dd>
            <dd className="mb-10">
              <Link
                to={getPath("FindASleepSpecialist")}
                className="text--brand-primary"
              >
                Find a Sleep Specialist
              </Link>
            </dd>
            <dd className="mb-10">
              <Link
                to={getPath("NarcolepsyConversationStarter")}
                className="text--brand-primary"
              >
                Narcolepsy Conversation Starter
              </Link>
            </dd>
          </dl>
        </div>

        <div className="col-12 col-sm-5 col-lg-6">
          <dl className="text__weight--base line-height--24 mt-xs-30">
            <dd className="mb-15">
              <Link
                to={getPath("LivingWithNarcolepsy")}
                className="text--brand-primary text__title"
              >
                Living With Narcolepsy
              </Link>
            </dd>
            <dd className="mb-10">
              <Link
                to={getPath("OverallHealth")}
                className="text--brand-primary"
              >
                {" "}
                Narcolepsy and Heart Health
              </Link>
            </dd>
            <dd className="mb-10">
              <Link
                to={getPath("TipsForWellBeing")}
                className="text--brand-primary"
              >
                Healthy Habits With Narcolepsy
              </Link>
            </dd>
            <dd className="mb-10">
              <Link
                to={getPath("TreatmentOptions")}
                className="text--brand-primary"
              >
                Treatment Options
              </Link>
            </dd>
          </dl>

          <dl className="text__weight--base line-height--24 mt-50">
            <dd className="mb-15">
              <Link
                to={getPath("Resources")}
                className="text--brand-primary text__title"
              >
                Resources
              </Link>
            </dd>
            <dd className="mb-10">
              <Link to={getPath("Resources")} className="text--brand-primary">
                {" "}
                Narcolepsy Resources
              </Link>
            </dd>
            <dd className="mb-10">
              <Link
                to={getPath("VideoLibrary")}
                className="text--brand-primary"
              >
                Patient Video Library
              </Link>
            </dd>
          </dl>

          <dl className="text__weight--base line-height--24 mt-50">
            <dd className="mb-50">
              <Link
                to={getPath("Community")}
                className="text--brand-primary text__title"
              >
                Community
              </Link>
            </dd>
            <dd className="mb-40">
              <Link
                to={getPath("StayConnected")}
                className="text--brand-primary text__title"
              >
                Stay Connected
              </Link>
            </dd>
            <dd className="mb-10">
              <Link to={getPath("Home")} className="text--brand-primary">
                Home
              </Link>
            </dd>
            <dd className="mb-10">
              <ModalExternalLink
                link={config.privacyStatement}
                customClassName="text--brand-primary"
              >
                Privacy Statement
              </ModalExternalLink>
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <StayConnected gtmTrackingContext={gtmTrackingContext} />
  </div>
)

export class StayConnected extends React.Component {
  constructor(props) {
    super(props)
    this.state = { userType: null }
    this.elements = {
      patient: React.createRef(),
      hcp: React.createRef(),
    }
  }

  componentDidMount() {
    const isPatient =
      this.elements.patient.current.getAttribute("checked") === ""
    this.setState(() => ({ userType: isPatient ? "patient" : "" }))
  }

  updateType(userType) {
    this.setState(() => ({ userType }))
  }
  render() {
    const { gtmTrackingContext } = this.props
    return (
      <div className="col-12 col-lg-6 mt-20">
        <div className="object--shadow object--border p-30">
          <p className="text__title text--brand-primary">Stay Connected</p>
          <p className="text__weight--base">
            Receive occasional updates <br className="d-sm-inline d-lg-none" />{" "}
            from More Than Tired about
            <br className="d-sm-inline d-lg-none" /> this educational
            initiative.
          </p>

          <div className="form-general">
            <div className="custom-control custom-radio">
              <input
                type="radio"
                id="connect1"
                name="connect"
                value="patient"
                className="custom-control-input"
                defaultChecked
                onChange={(e) => this.updateType("patient")}
                ref={this.elements.patient}
              />
              <label
                className="custom-control-label text__weight--bold"
                htmlFor="connect1"
              >
                I am a patient, family member, or friend
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                type="radio"
                id="connect2"
                name="connect"
                value="hcp"
                className="custom-control-input"
                onChange={(e) => this.updateType("hcp")}
                ref={this.elements.hcp}
              />
              <label
                className="custom-control-label text__weight--bold"
                htmlFor="connect2"
              >
                I am a healthcare professional
              </label>
            </div>
            {this.state.userType === "patient" ? (
              <TrackingWrapper
                gtmTrackId={gtmTrackingContext + "-stayConnectedPatient"}
              >
                <Link
                  to={getPath("StayConnected")}
                  className="btn btn--primary mt-20"
                >
                  Submit
                </Link>
              </TrackingWrapper>
            ) : (
              <ModalExternalLink
                link={`${narcolepsyLink}${joinNlPath}`}
                customClassName="btn btn--primary mt-20"
              >
                <TrackingWrapper
                  gtmTrackId={gtmTrackingContext + "-stayConnectedHCP"}
                >
                  <span>Submit</span>
                </TrackingWrapper>
              </ModalExternalLink>
            )}
          </div>
        </div>
      </div>
    )
  }
}
