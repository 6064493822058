import React, { useState, useEffect } from 'react';
import { YourNextSteps } from '@component-structure/PediatricScreenerElements';
import { DownloadOverlay, EmailOverlay } from '@component-structure/AdultScreenerElements';
import ScreenerCHAD from '@component-structure/ScreenerCHAD';
import { ModalExternalLink } from '@component-structure/ModalExternalLink';
import AppStore from '@images/apple_app_store.png';
import GooglePlay from '@images/google_play.png';

const PediatricSymptomScreener = () => {
	useEffect(() => {
		document.getElementsByClassName('footer__top-text')[0].innerHTML =
			'This screening tool is not intended to make a narcolepsy diagnosis or replace complete evaluation by a sleep specialist.';

		return () => {
			document.getElementsByClassName('footer__top-text')[0].innerHTML =
				'The content on this site is not meant to replace a conversation with a sleep specialist. A sleep specialist can evaluate your symptoms and make a diagnosis.';
		};
	}, []);

	const [screenerType, setScreenerType] = useState(null);

	const [isOpenDownload, setIsOpenDownload] = useState(false);

	const toggleDownloadModal = (e) => {
		e.preventDefault();
		setIsOpenDownload(!setIsOpenDownload);
	};

	const [isOpenEmail, setIsOpenEmail] = useState(false);

	const toggleEmailModal = (e) => {
		e.preventDefault();
		setIsOpenEmail(!setIsOpenEmail);
	};

	return (
		<div className='pediatric-screener-page'>
			<div className='screeners-wrapper'>
				<ScreenerCHAD
					setIsOpenDownload={setIsOpenDownload}
					setIsOpenEmail={setIsOpenEmail}
					setScreenerType={setScreenerType}
				/>
				<YourNextSteps />
			</div>
			<DownloadOverlay
				isOpen={isOpenDownload}
				toggle={toggleDownloadModal}
				adultDownload={false}
				screenerType={screenerType}
			/>
			<EmailOverlay
				isOpen={isOpenEmail}
				toggle={toggleEmailModal}
				adultEmail={false}
				screenerType={screenerType}
			/>
		</div>
	);
};

export default PediatricSymptomScreener;
