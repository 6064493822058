import React, { Component } from "react"
import { Link } from "gatsby"
import logo from "@images/jazz-pharmaceuticals-logo-white.png"
import config from "@config/config"
import { getPath } from "@component-utility/pagelist"
//import TvAd from "./TvAd"
import { ModalExternalLink } from "@component-structure/ModalExternalLink"
import { TrackingWrapper } from "@component-utility/TrackingTools"


export class Footer extends Component {

  constructor(props) {
    super(props)
  }

  render() {
  // @TODO: below method logic we might need that in coming days for variation with reva-code or job-code
/*   
    const getLastUpdatedValue = () => {
    const currentPath = window.location.pathname
    let lastupdated

    if (getPath("StayConnected") === currentPath || getPath("WhatIsNarcolepsy") === currentPath || getPath("PotentialImpact") === currentPath 
    || getPath("WhatCausesNarcolepsy") === currentPath || getPath("PediatricNarcolepsy") === currentPath || getPath("CaringForYourChild") === currentPath 
    || getPath("CouldItBeNarcolepsy") === currentPath)
    {
      lastupdated = 'November 04, 2021'
    } else
    {
      lastupdated = 'July 12 2022'
    }
    return lastupdated
  } 
  */

    return (
        <footer className="footer">
          <div className="footer__top">
            <div className="container">
              <div className="footer__top-text">
                The content on this site is not meant to replace a conversation
                with a sleep specialist. A sleep specialist can evaluate your
                symptoms and make a diagnosis.
              </div>
            </div>
          </div>
          <div className="footer__main">
            <div className="container">
              <div className="footer__main-logo">
                <ModalExternalLink link={config.jazzPharmaceuticals}>
                  <img src={logo} alt="Jazz Pharmaceuticals Logo" />
                </ModalExternalLink>
              </div>
              <div className="d-flex flex-column flex-md-row justify-content-between">
                <div className="footer__main-info order-2 order-md-1">
                  <div className="footer__main-info-text">
                    <p>More Than Tired™ is sponsored by Jazz Pharmaceuticals</p>
                    <p>
                    &#169; 2024 Jazz Pharmaceuticals plc or its subsidiaries <br className="hidden-lg-up"/>US-SLE-2000370 Rev0424
                    </p>
                  </div>
                </div>
                <div className="footer__main-list order-1 order-md-2">
                  <ul className="footer__main-ul">
                    <li className="footer__main-ul-item">
                      <ModalExternalLink link={config.facebookMtt}>
                        <TrackingWrapper gtmTrackId="footerFacebook">
                          <i
                            className="ion ion-social-facebook ml-5 mr-5 footer__main-ul-link"
                            aria-hidden="true"
                          />
                          <span className="footer__main-ul-link">
                            Join the Conversation
                          </span>
                        </TrackingWrapper>
                      </ModalExternalLink>
                    </li>
                    <li className="footer__main-ul-item">
                      <ModalExternalLink link={config.instagramMtt}>
                        <TrackingWrapper gtmTrackId="footerInsta">
                          <i
                            className="ion ion-social-instagram mr-5 footer__main-ul-link"
                            aria-hidden="true"
                          />
                          <span className="footer__main-ul-link">
                            Follow Us on Instagram
                          </span>
                        </TrackingWrapper>
                      </ModalExternalLink>
                    </li>
                    <li className="footer__main-ul-item no-icon">
                      <TrackingWrapper gtmTrackId="footerFASS">
                        <Link
                          to={getPath("FindASleepSpecialist")}
                          title="Find a sleep specialist"
                          className="footer__main-ul-link"
                        >
                          Find a Sleep Specialist
                        </Link>
                      </TrackingWrapper>
                    </li>
                    <li className="footer__main-ul-item no-icon">
                      <ModalExternalLink link={config.privacyStatement} customClassName="footer__main-ul-link">
                        <TrackingWrapper gtmTrackId="footerPrivacy">
                          <span className="footer__main-ul-link">
                            Privacy Statement
                          </span>
                        </TrackingWrapper>
                      </ModalExternalLink>
                    </li>
                    <li className="footer__main-ul-item no-icon">
                      <TrackingWrapper gtmTrackId="footerSitemap">
                        <Link
                          to={getPath("Sitemap")}
                          title="Sitemap"
                          className="footer__main-ul-link"
                        >
                          Site Map
                        </Link>
                      </TrackingWrapper>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
    )
  }
}

export default Footer
