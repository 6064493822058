import React, { Component } from "react";
import PropTypes from "prop-types";

export class PhysicianDetail extends Component {
  render() {
    return (
      <div className="physician-details-wrapper">
        <div className="physician-details-content">
          <div className="button-wrapper">
            <button onClick={() => this.props.closePhysicianDetails()}>
              <i className="fa fa-chevron-left" />
            </button>
          </div>
          <div className="physician-details">
            <h1 className="physician-name">
              {this.props.doctor.First} <br /> {this.props.doctor.Last},{" "}
              {this.props.doctor.ProfessionalDesignation}
            </h1>
            <div className="physicain-address-wrapper">
              {this.props.doctor.Address1} {this.props.doctor.Address2}
              <br />
              {this.props.doctor.City}, {this.props.doctor.State}{" "}
              {this.props.doctor.Zip}
            </div>
            <div className="phone-number">{this.props.doctor.Phone}</div>
            <div className="distance">
              {Math.round(this.props.doctor.DistanceFromRequest)} miles away
            </div>
            {this.props.doctor.PediatricSpecialist === true && (
              <p className="pediatric-specialist">
                <i className="fa fa-check-circle" />{" "}
                <span>Pediatric patients welcome</span>
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PhysicianDetail;

PhysicianDetail.propTypes = {
  doctor: PropTypes.object,
  closePhysicianDetails: PropTypes.func
};
